<div class="flex flex-col lg:flex-row gap-2 p-2">
  <app-category-select (selectionChanged)="handleCategorySelectionChanged($event)"
                       [relatedCategoryEntries]="response?.categories ??  []"
                       *ngIf="categorySelectorVisible" class="basis-1/4">
  </app-category-select>

  <div class="flex flex-col gap-2 flex-auto justify-start">
    <mat-progress-bar *ngIf="searching" mode="indeterminate"></mat-progress-bar>
    <app-series-board-content-result-list [response]="response" (page)="handlePageEvent($event)">

    </app-series-board-content-result-list>
  </div>
</div>
