import { Injectable } from '@angular/core';
import moment from "moment/moment";

@Injectable({
  providedIn: 'root'
})
export class SystemTimeService {

  timezone: string = moment.tz.guess()
  constructor() { }

  formatTimestamp(timestamp: string): string {
    const m = moment.utc(timestamp).tz(this.timezone)
    return m.format("YYYY-MM-DDTHH:mm:ss")
  }
}
