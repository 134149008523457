import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CategorySelectComponent, CategorySelectionChange } from "../../category/category-select/category-select.component";
import { DocumentService } from "../model/document.service";
import { DocumentSearchRequest, DocumentSearchResponse, DocumentTypeResultEntry, RelatedCategoryIdEntry } from "../model/document-api";
import { TranslateService } from "@ngx-translate/core";
import { TypeSelectionChange } from "../document-board-content-type-select/document-board-content-type-select.component";
import { PageEvent } from "@angular/material/paginator";
import { Subscription } from "rxjs";
import { HotToastService } from "@ngxpert/hot-toast";

let DEFAULT_PAGE_SIZE = 25;

@Component({
  selector: 'app-document-board-content',
  templateUrl: './document-board-content.component.html',
  styleUrl: './document-board-content.component.scss'
})
export class DocumentBoardContentComponent {

  @Input() categorySelectorVisible: boolean = true
  @Input() typeSelectorVisible: boolean = true

  @Input() set productId(data: string | undefined) {
    this.request.productIds = data ? [data] : []
  }

  @Output() reset = new EventEmitter<boolean>()
  @Output() requestChanged = new EventEmitter<DocumentSearchRequest>()

  searching: boolean = false
  pageSize: number = DEFAULT_PAGE_SIZE
  pageIndex: number = 0
  totalSize: number = -1

  response: DocumentSearchResponse | null = null

  types: DocumentTypeResultEntry[] = []
  categories: RelatedCategoryIdEntry[] = []
  country: string = ''


  request: DocumentSearchRequest = new DocumentSearchRequest([], [], '', this.translate.currentLang, '', [], [])


  private subscriptions: Subscription[] = []

  @ViewChild(CategorySelectComponent) categorySelect: CategorySelectComponent | undefined

  constructor(
    private service: DocumentService,
    private translate: TranslateService,
    private toast: HotToastService,
  ) {

  }

  ngOnInit() {
    let s1 = this.translate.onLangChange.subscribe(evt => {
      this.request.lang = evt.lang
      this.handleRequestChanged()
    })
    this.subscriptions = [s1]
  }

  ngAfterContentInit() {
    this.load()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []
  }


  handleCategorySelectionChanged(event: CategorySelectionChange) {
    this.request.selectedApplicationId = event.selectedApplicationId
    this.request.selectedTechnologyId = event.selectedTechnologyId
    this.request.categoryIds = event.getSelectedCategoryIds()
    this.handleRequestChanged()
  }


  handleTypeSelectionChanged(event: TypeSelectionChange) {
    this.request.documentTypes = event.selectedTypes
    this.handleRequestChanged()
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex
    this.pageSize = event.pageSize
    this.load()
  }

  resetFilter() {
    this.request = new DocumentSearchRequest([], [], '', this.translate.currentLang, this.country, [], [])
    this.reset.emit()
    if (this.categorySelect) this.categorySelect.reset()
    this.handleRequestChanged()
  }

  search(query: string) {
    this.request.fullTextSearch = query
    this.handleRequestChanged()
  }

  private load(overwrite: boolean = false) {
    if (this.searching && !overwrite) return
    this.searching = true

    this.service.searchDocuments(this.request, this.pageIndex, this.pageSize)
      .subscribe({
        next: value => this.handleData(value),
        error: err => this.handleError()
      })
  }

  private handleData(response: DocumentSearchResponse | null) {
    if (response) {
      this.response = response
      this.pageIndex = response.entries.pageable.number
      this.totalSize = response.entries.totalSize
      this.types = response.types
      this.categories = response.categories
    } else {
      this.handleError()
    }
    this.searching = false
  }

  private handleError() {
    this.response = null
    this.pageIndex = 0
    this.totalSize = 0
    this.types = []
    this.categories = []
    this.toast.error("Something went wrong, please reset the search")
    this.searching = false
  }

  private handleRequestChanged() {
    this.pageIndex = 0
    this.requestChanged.emit(this.request)
    this.load()
  }

  updateRequest(query: string, documentTypes: string[], productIds: string[], selectedApplicationId: string, selectedTechnologyId: string, country: string) {
    this.request.fullTextSearch = query
    this.request.documentTypes = documentTypes
    this.request.selectedApplicationId = selectedApplicationId
    this.request.selectedTechnologyId = selectedTechnologyId
    this.request.categoryIds = [selectedApplicationId, selectedTechnologyId].filter(item => item.length > 0)
    this.request.productIds = productIds
    this.request.country = country
    this.country = country
    if (this.categorySelect) {
      this.categorySelect.updateRequest(this.request, country)
    }
    this.load()
  }

}
