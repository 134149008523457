import { Component, Input, OnDestroy } from '@angular/core';
import { SparePartsCategoryService } from "../model/spare-parts-category.service";
import { Subscription } from "rxjs";
import {
  WishlistAddEntry,
  WishlistAddEntryDialogComponent
} from "../../wishlist/wishlist-add-entry-dialog/wishlist-add-entry-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { WishlistService } from "../../wishlist/model/wishlist.service";
import { WishlistEntry } from "../../wishlist/model/wishlist-api";

@Component({
  selector: 'app-spare-parts-category-details',
  templateUrl: './spare-parts-category-details.component.html',
  styleUrls: ['./spare-parts-category-details.component.scss']
})
export class SparePartsCategoryDetailsComponent implements OnDestroy {

  @Input() imageMaxHeight: string = ''

  displayedColumns = ['text', 'content']
  content: SparePartPropertyEntry[] = []
  subscriptions = new Subscription()
  wishlistActive: WishlistEntry[] = []

  constructor(
    public service: SparePartsCategoryService,
    private dialog: MatDialog,
    private wishlistService: WishlistService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(this.service.selectedSparePartChanged.subscribe(() => this.updateTableOfContent()))
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  private updateTableOfContent() {
    let sparePart = this.service.selectedSparePart
    if (sparePart) {
      this.updateWishlistActive()
      this.content = [
        { text: 'spareParts.id', content: sparePart.id },
        { text: 'spareParts.description', content: sparePart.description },
        { text: 'spareParts.use', content: sparePart.usage },
        { text: 'spareParts.info', content: sparePart.information },
        { text: 'spareParts.weight', content: sparePart.weight },
        { text: 'spareParts.dimension', content: sparePart.dimensions },
        { text: 'spareParts.quantity', content: sparePart.quantity },
        { text: 'spareParts.year', content: sparePart.fromYear },
      ]
    } else {
      this.content = []
    }
  }

  addToWishList() {
    const sparePart = this.service.selectedSparePart
    if (sparePart) {
      const ref = this.dialog.open(WishlistAddEntryDialogComponent, {
        width: '50%',
        data: {
          entry: new WishlistAddEntry(sparePart.id, sparePart.description),
          entries: [new WishlistAddEntry(sparePart.id.padStart(18, '0'), sparePart.description)]
        }
      });
      ref.afterClosed().subscribe(_ => this.updateWishlistActive())
    }
  }

  private updateWishlistActive() {
    const sparePart = this.service.selectedSparePart
    if (sparePart) {
      this.wishlistService.checkWishlist([sparePart.id.padStart(18, '0')]).subscribe(data => {
        this.wishlistActive = data
      })
    }
  }

  isWishlistActive() {
    const sparePart = this.service.selectedSparePart
    if (sparePart) {
      const active = this.wishlistActive.find(e => parseInt(e.reference) == parseInt(sparePart.id))
      return active != undefined
    }
    return false
  }
}


interface SparePartPropertyEntry {
  text: string
  content: any
}
