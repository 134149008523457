import { Page } from "../../../utils/page";
import { RelatedCategoryIdEntry } from "../../document/model/document-api";
import { CategorySelectRequest } from "../../category/category-select/category-select.component";

export interface Product {
  id: string,
  category: string,
  series: string,
  legacy: false,
  internal: false,
  bestRef: number,
  orderKey: number,
  motorType: number,
  attributes: ProductAttributes
  references: ProductReferences
}


export interface ProductAttributes {
  general: GeneralAttributes | null,
  image: ImageAttributes | null,
  roHS: RoHSAttributes | null,
  dimension: ProductDimension | null,
  supplementalParameter: SupplementalParameter | null,
  limit: ProductLimit | null
  fan: ProductFan | null
  production: ProductionInfo | null
  workingFluids: WorkingFluids | null
  pipe: PipeInfo | null
}

export interface PipeInfo {
  connections: PipeConnection[]
}

export interface PipeConnection {
  size: string,
  unit: string,
  material: string,
  name: string,
}

export interface WorkingFluids {
  fluidVolume: string
  workingFluids: string[]
  workingFluidCharge: Map<string, string>
  flowWorkingFluids: FlowWorkingFluids
}

export interface FlowWorkingFluids {
  'Fan 50Hz': string
}

export interface ProductionInfo {
  deliveryTime: string
}

export interface ProductFan {
  fanVoltage: FanInfo
  fanPower: FanInfo
  fanFlow: FanInfo
}

export interface FanInfo {
  '50Hz': string
  '60Hz': string
}

export interface ProductLimit {
  maxPressure: string
  maxTemperature: string
}

export interface ProductDimension {
  depth: string,
  height: string,
  width: string
}

export interface SupplementalParameter {
  compressorSpecification: string,
  noiseLevel: NoiseLevel | null
}

export interface NoiseLevel {
  sl: string,
  sd: string
}

export interface GeneralAttributes {
  weight: string,
  cylinderCount: string,
  connectorDischarge: string,
  connectorSuction: string,
  oilCharge: string,
  displacement: Map<string, string>,
  maxOperatingCurrent: Map<string, string>,
  oilType: Map<string, string>,
  motorVoltage: string,
  maxStartCurrent: string,
}

export interface ImageAttributes {
  images: ImageAttribute[]
}

export interface ImageAttribute {
  fileName: string,
  url: string,
  type: string,
}

export interface RoHSAttributes {
  casNumbers: string[]
}

export interface ProductReferences {
  document: DocumentReferences | null,
  spareParts: SparePartReferences | null,
  pids: ProductIdReferences | null,
}

export interface DocumentReferences {
  documents: string[]
}

export interface SparePartReferences {
  category: SparePartCategory[]
}

export interface SparePartCategory {
  id: number,
  name: {
    de: string,
    en: string,
    fr: string,
    it: string,
    es: string,
  },
  media: string[],
  spareParts: string[],
}

export interface ProductIdReferences {
  matNrKMat: string,
  pids: string[],
}


export class ProductSearchRequest implements CategorySelectRequest {
  constructor(
    public categoryIds: string[],
    public technologyIds: string[],
    public seriesIds: string[],
    public fullTextSearch: string,
    public lang: string
  ) {
  }

  selectedApplicationId: string = '';
  selectedTechnologyId: string = '';
}

export interface ProductSearchResponse {
  entries: Page<ProductSearchResultEntry>,
  technology: ProductTechnologyResultEntry[],
  series: ProductSeriesResultEntry[],
  categories: RelatedCategoryIdEntry[]
}

export interface ProductSearchResultEntry {
  id: string,
  technology: string,
  technologyName: string,
  series: string,
  seriesName: string,
  legacy: boolean,
  internal: boolean,
  matNrKMat: string,
  hasDocuments: boolean,
  hasSpareParts: boolean,
  hasProductConfigIds: boolean
}

export interface ProductTechnologyResultEntry {
  technology: string,
  amount: number
}

export interface ProductSeriesResultEntry {
  series: string,
  seriesName: string,
  amount: number
}


export interface ProductInfo {
  id: string,
  technology: string,
  series: string,
  legacy: boolean,
  internal: boolean
}
