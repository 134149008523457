<div class="flex flex-row flex-wrap gap-2 p-2">
  @for (s of entries; track s) {
    <app-list-card-entry
      class="series-list-entry"
      (click)="showDetails(s)"
      imageUrl="https://www.bitzer.de/shared_media/product_images/images_f_p/{{s.picture}}"
      [title]="s.title"
    ></app-list-card-entry>
  }
</div>

<mat-paginator [length]="totalSize"
               [pageSize]="pageSize"
               [pageIndex]="pageIndex"
               (page)="handlePaginatorEvent($event)"
               [pageSizeOptions]="[5, 10, 25, 100]"
               class="dense-1 pb-3" *ngIf="totalSize > 0 && totalSize > pageSize">
</mat-paginator>
