import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SparePart } from "../../model/spare-part-api";

@Component({
  selector: 'app-spare-part-image-dialog',
  templateUrl: './spare-part-image-dialog.component.html',
  styleUrls: ['./spare-part-image-dialog.component.scss']
})
export class SparePartImageDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: SparePart
  ) {
  }

}
