import { Injectable } from '@angular/core';
import moment from "moment";
import { FormBuilder } from "@angular/forms";
import { distinctUntilChanged, Subject } from "rxjs";
import { Equipment } from "../../model/equipment-api";
import { PropertyCategory, PropertyDefinition, ThingTemplate } from "../../../thing-template/model/thing-template-api";
import { debounceTime } from "rxjs/operators";
import { ElectronicModule } from "../../../module/model/module-api";
import { System } from "../../../system/model/system-api";
import { EquipmentService } from "../../model/equipment.service";
import { SystemService } from "../../../system/model/system.service";
import { SystemTimeService } from "../../../system/system-time/system-time.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ThingTemplateService } from "../../../thing-template/model/thing-template.service";

@Injectable({
  providedIn: 'root'
})
export class OperationalGraphService {

  selectedTime: string = '1'
  range = this.fb.group({
    start: this.fb.control(moment().utc().subtract(1, 'days').startOf('day')),
    end: this.fb.control(moment().utc().subtract(0, 'days').endOf('day'))
  })

  equipments: Equipment[] = []
  systems: System[] = []
  templates: ThingTemplate[] = []
  selectedProperties: PropertyDefinition[] = []
  predefinedProperties: string[] = ['BDN.CondTemp', 'BDN.TempAux', 'BDN.OilDiscGasTemp', 'BDN.EvapTemp']

  private changeSubject: Subject<boolean> = new Subject()
  changeEvent = this.changeSubject.pipe(debounceTime(500))

  readonly secondGraphMeasures = ['UNIT_HZ', 'UNIT_PCT', 'NONE']

  constructor(
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private systemService: SystemService,
    private systemTime: SystemTimeService,
    private thingTemplateService: ThingTemplateService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    this.range.get('end')?.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
          if (value) this.notifyChanged()
        }
      )
  }

  filterOnPreset() {
    if (!this.selectedTime) return
    const now = moment()
    let end = now
    const amount = parseInt(this.selectedTime, 10)
    let start = moment(now).subtract(amount, 'days')
    this.range.setValue({ start: start, end: end })
    this.setTimeRouteParams()
  }

  calculateMin() {
    const timestamp = this.range.value.start?.startOf('day').format().toString() ?? ''
    return this.systemTime.formatTimestamp(timestamp)
  }

  calculateMax() {
    const timestamp = this.range.value.end?.endOf('day').format().toString() ?? ''
    return this.systemTime.formatTimestamp(timestamp)
  }

  getRangeStart() {
    let rangeValue = this.range.value
    return rangeValue.start?.format().toString() ?? moment().utc().subtract(15, 'days').startOf('day').format().toString()
  }

  getRangeEnd() {
    let rangeValue = this.range.value
    return rangeValue.end?.format().toString() ?? moment().utc().subtract(0, 'days').endOf('day').format().toString()
  }

  addEntry(equipment: Equipment, template: ThingTemplate) {
    let index = this.equipments.indexOf(equipment)
    if (index < 0) {
      this.equipments.push(equipment)
      this.templates.push(template)
    } else {
      this.equipments[index] = equipment
      this.templates[index] = template
    }
  }

  onPropertySelect(properties: PropertyDefinition[]) {
    this.selectedProperties = [...properties]
    this.notifyChanged()
  }

  predefineProperties(modules: ElectronicModule[]) {
    if (!modules.length) return
    modules.forEach(m => this.setSelectedProperties(m))
    this.updateSelectedProperties()
  }

  trimPropertyName(name: string) {
    const property= name.replace('BDN.', '')
    return property.charAt(0).toLowerCase() + property.slice(1)
  }

  switchSystemTime(systemTime: boolean) {
    if (systemTime) {
      this.findSystemTimezone()
      return
    }
    this.systemTime.timezone = moment.tz.guess()
    this.refresh()
  }

  private notifyChanged(reload: boolean = true) {
    this.changeSubject.next(reload)
    this.setTimeRouteParams()
  }

  refresh() {
    this.notifyChanged(false)
  }

  reset() {
    this.equipments = []
    this.templates = []
    this.selectedProperties = []
    this.predefinedProperties = ['BDN.CondTemp', 'BDN.TempAux', 'BDN.OilDiscGasTemp', 'BDN.EvapTemp']
  }

  loadTemplates() {
    this.equipments.forEach(e => {
      this.thingTemplateService.getEquipmentTemplate(e.id).subscribe(data => {
        const idx = this.templates.findIndex(t => t.id === data.id)
        if (idx !== -1) return
        this.templates.push(data)
      })
    })
  }

  private updateSelectedProperties() {
    let categories: PropertyCategory[] = []
    categories = categories.concat(...this.templates.map(t => t.categories))
    categories.forEach(c => {
      const predefined = c.properties.filter(p => this.predefinedProperties.includes(p.name))
      this.selectedProperties = this.selectedProperties.concat(predefined)
    })
  }

  private setSelectedProperties(module: ElectronicModule) {
    switch (module.type) {
      case "CM-RC-02":
      case "CM-RC-01": {
        this.predefinedProperties = [...this.predefinedProperties, 'BDN.Capacity', 'BDN.SuctionPressure', 'BDN.DischargePressure']
        break
      }
      case "CSV": {
        this.predefinedProperties = [...this.predefinedProperties, 'BDN.Capacity']
        break
      }
      case "CM-SW-01":
      case "SE-i1":
      case "ECOSTAR-LHV5E7E": {
        this.predefinedProperties = [...this.predefinedProperties, 'BDN.MotorFrequency']
        break
      }
      case "VARIPACK":
      case "VARIPACK2":
      case "ECOLITE":
      case "ECOLITE.A2L": {
        this.predefinedProperties = [...this.predefinedProperties, 'BDN.Capacity', 'BDN.MotorVoltage', 'BDN.MotorCurrent', 'BDN.MotorPower']
        break
      }
    }
  }

  private findSystemTimezone() {
    this.equipmentService.getPath(this.equipments[0].id).subscribe(path => {
      const systemId = path[0].children[0].resource.id
      this.systemService.getSystem(systemId).subscribe(system => {
        this.systemTime.timezone = system.timezone
        this.refresh()
      })
    })
  }

  private setTimeRouteParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        start: this.range.get('start')?.value,
        end: this.range.get('end')?.value
      },
      queryParamsHandling: 'merge'
    }).then()
  }
}


