import { Page } from "../../../utils/page";

export interface SparePartCategoryInfo {
  name: {
    de: string,
    en: string,
    fr: string,
    it: string,
    es: string,
  },
  spareParts: SparePart[],
  categories: Map<string, CategoryAttributes>,
  media: string
}

export interface CategoryAttributes {
  categoryId: number,
  containedIn: string,
  contains: string,
  fromYear: string,
  position: number,
  quantity: number,
  replacedBy: string,
  serialNumber: string,
}


export interface SparePart {
  id: string,
  categoryAttributes: Map<number, CategoryAttributes>,
  categoryIds: number[],

  position: number,
  replacedBy: string,
  contains: string,
  containedIn: string,
  serialNumber: string,
  fromYear: string,
  quantity: number,
  price: string,
  weight: string,
  dimensions: string,
  picture: string,

  information: any,
  material: any,
  description: any,
  usage: any,
}

export interface CategorySparePart {
  id: string,
  price: string,
  weight: string,
  dimensions: string,
  picture: string,

  position: number,
  replacedBy: string,
  contains: string,
  containedIn: string,
  serialNumber: string,
  fromYear: string,
  quantity: number,
  media: string,

  information: string,
  material: string,
  description: string,
  usage: string
}

export interface LocalizedSparePart {
  id: string,
  categoryIds: number[],

  price: string,
  customerPrice: string,
  weight: string,
  dimensions: string,
  picture: string,

  information: string,
  material: string,
  description: string,
  usage: string
}


export class SparePartSearchRequest {
  constructor(
    public fullTextSearch: string,
    public lang: string,
  ) {
  }
}

export interface SparePartSearchResponse {
  entries: Page<SparePartSearchResultEntry>
}

export interface SparePartSearchResultEntry {
  id: string,
  picture: string,
  description: string,
}

export interface SparePartInfo {
  id: string,

  price: string,
  weight: string,
  dimensions: string,
  picture: string,

  information: string,
  material: string,
  description: string,
  usage: string,
}
