import { Component } from '@angular/core';
import { Subscription } from "rxjs";
import { ProductConfigSearchService } from "../model/product-config-info.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-product-config-search',
  templateUrl: './product-config-search.component.html',
  styleUrl: './product-config-search.component.scss'
})
export class ProductConfigSearchComponent {
  subscriptions: Subscription[] = []

  constructor(
    public service: ProductConfigSearchService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const s1 = this.route.queryParams.subscribe(params => {
      const productId = params['product']
      this.service.selectProduct(productId)
    })

    this.subscriptions.push(s1)
  }

  reset(): void {
    this.service.resetSelection()
  }

  navigateToPidComparison(): void {
    const configIds = this.service.result.entries.map(i => i.id)
    this.router.navigate(['portal', 'pid', 'compare'], {queryParams : {p : configIds}}).then()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []
  }
}
