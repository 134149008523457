import { EventSearchRequest, StatusEvent } from "../../../event/event-api";
import { EventService } from "../../../event/event.service";
import { Equipment } from "../../model/equipment-api";
import { SeriesOption } from "echarts";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { OperationalGraphService } from "../model/operational-graph.service";
import { BaseLoader, LoaderCallback } from "./base-loader";
import { Subject } from "rxjs";
import { SystemTimeService } from "../../../system/system-time/system-time.service";

export class EventLoader extends BaseLoader<StatusEvent> {

  private readonly STATUS_EVENT_CATEGORY = 1
  private readonly ONLINE_EVENT_CATEGORY = 2
  private readonly START_EVENT_CODE = 4
  private readonly STOP_EVENT_CODE = 5
  private readonly FAULT_EVENT_CODE = 6
  private readonly ONLINE_EVENT_CODE = 7
  private readonly OFFLINE_EVENT_CODE = 8

  constructor(
    private operationalGraphService: OperationalGraphService,
    private service: EventService,
    private translate: TranslateService,
    private systemTime: SystemTimeService,
  ) {
    super()
  }

  loadPage(equipment: Equipment, from: string, to: string, page: number, data: StatusEvent[], callback: Subject<LoaderCallback>) {
    let request = new EventSearchRequest(from, to)
    this.service.findEvents(equipment.id, request, page, this.PAGE_SIZE)
      .subscribe(d => this.handlePage(equipment, from, to, page, data, callback, d))
  }

  processData(events: StatusEvent[], callback: Subject<LoaderCallback>) {
    let runningMarker: EventMarker[] = []
    let offlineMarker: EventMarker[] = []

    let lastStartEvent: StatusEvent | undefined
    let lastOfflineEvent: StatusEvent | undefined
    let lastStopEvent: StatusEvent | undefined
    let lastOnlineEvent: StatusEvent | undefined

    for (let i = 0; i < events.length; i++) {
      let current = events[i]
      let isStartEvent = current.code == this.START_EVENT_CODE
      let isOfflineEvent = current.code == this.OFFLINE_EVENT_CODE

      if (current.code == this.STOP_EVENT_CODE) {
        lastStopEvent = events[i]
      }
      if (current.code == this.FAULT_EVENT_CODE) {
        lastStopEvent = events[i]
      }
      if (current.code == this.ONLINE_EVENT_CODE) {
        lastOnlineEvent = events[i]
      }

      let isBeginEvent = isStartEvent || isOfflineEvent
      if (!isBeginEvent) {
        if (isStartEvent) lastStartEvent = current
        if (isOfflineEvent) lastOfflineEvent = current
        continue
      }

      if (isStartEvent) {
        lastStartEvent = undefined
        lastStopEvent = undefined
      }
      if (isOfflineEvent) {
        lastOfflineEvent = undefined
        lastOnlineEvent = undefined
      }

      let next = this.findNextEvent(events, current, i)

      if (isStartEvent) {
        this.addEventMarker(current, next, runningMarker)
      } else if (isOfflineEvent) {
        this.addEventMarker(current, next, offlineMarker)
      }
    }

    if (lastStartEvent) this.addEventMarker(lastStartEvent, undefined, runningMarker)
    if (lastOfflineEvent) this.addEventMarker(lastOfflineEvent, undefined, offlineMarker)
    if (lastStopEvent) this.addEventMarker(undefined, lastStopEvent, runningMarker)
    if (lastOnlineEvent) this.addEventMarker(undefined, lastOnlineEvent, offlineMarker)

    let faultEvents = events.filter((event) => event.code === 6)
    let faultSeries = this.createFaultSeries(faultEvents)

    let runningSeries = this.createMarkSeries(
      'Running',
      .4,
      '#b9e5b8',
      runningMarker
    )

    let onlineSeries = this.createMarkSeries(
      'Offline',
      .5,
      'grey',
      offlineMarker
    )
    callback.next({ primary: [runningSeries, onlineSeries, ...faultSeries], secondary: [runningSeries, onlineSeries] })
  }

  private createFaultSeries(faultEvents: StatusEvent[]): SeriesOption[] {
    return faultEvents.map((event) => {
      return {
        type: 'scatter',
        tooltip: {
          show: false
        },
        data: [[event.timestamp, 250]],
        symbol: 'none',
        markLine: {
          symbol: 'none',
          lineStyle: {
            type: 'dashed',
            color: 'red'
          },
          label: {
            show: false,
          },
          data: [{
            xAxis: this.systemTime.formatTimestamp(event.timestamp)
          }]
        }
      }
    })
  }

  private findNextEvent(events: StatusEvent[], current: StatusEvent, index: number) {
    let nextEvent: number[] = []
    switch (current.code) {
      case this.START_EVENT_CODE: {
        nextEvent = [this.STOP_EVENT_CODE, this.FAULT_EVENT_CODE]
        break
      }
      case this.OFFLINE_EVENT_CODE: {
        nextEvent = [this.ONLINE_EVENT_CODE]
        break
      }
      case this.STOP_EVENT_CODE: {
        nextEvent = [this.START_EVENT_CODE]
        break
      }
      case this.FAULT_EVENT_CODE: {
        nextEvent = [this.START_EVENT_CODE]
        break
      }
      case this.ONLINE_EVENT_CODE: {
        nextEvent = [this.OFFLINE_EVENT_CODE]
        break
      }
    }
    if (nextEvent.length <= 0) return undefined
    for (let i = index; i >= 0; i--) {
      let evt = events[i]
      let isNextEvent = nextEvent.indexOf(evt.code) >= 0
      if (isNextEvent) {
        return evt
      }
    }
    return undefined
  }

  private createEventMarker(first: undefined | StatusEvent, last: undefined | StatusEvent): EventMarker | undefined {
    if (!first && last) {
      return { begin: this.operationalGraphService.calculateMin(), end: this.systemTime.formatTimestamp(last.timestamp) }
    } else if (first && !last) {
      return { begin: this.systemTime.formatTimestamp(first.timestamp), end: this.operationalGraphService.calculateMax() }
    } else if (first && last) {
      return { begin: this.systemTime.formatTimestamp(first.timestamp), end: this.systemTime.formatTimestamp(last.timestamp) }
    }
    return undefined
  }


  private createMarkSeries(title: string, opacity: number, color: string, marker: EventMarker[]): SeriesOption {
    return {
      name: title === 'Running' ? this.translate.instant('ioTStatusText_Running') : this.translate.instant('ioTStatusText_Offline'),
      type: 'line',
      areaStyle: {},

      markPoint: {
        label: {
          show: false,
        },
      },
      lineStyle: {
        width: 1
      },
      emphasis: {
        focus: 'series'
      },
      markArea: {
        emphasis: {
          label: {
            show: true,
            color: '#000',
            backgroundColor: 'white',
            borderColor: '#bfbfbf',
            borderWidth: 0.8,
            padding: 8,
            opacity: 1,
            distance: -5,
            formatter: (params) => {
              // @ts-ignore
              const t = params.data.text
              const text = t === 'Running' ? this.translate.instant('ioTStatusText_Running') : this.translate.instant('ioTStatusText_Offline')
              // @ts-ignore
              const start = new Date(params.data.coord[0][0]);
              // @ts-ignore
              const end = new Date(params.data.coord[1][0]);
              const duration = end.getTime() - start.getTime();
              return `${ text }: ${ this.formatDuration(duration) }`;
            },
          },
        },
        itemStyle: {
          opacity: opacity,
          color: color,
        },
        data: marker.map(m => [{ text: title, xAxis: m.begin }, { xAxis: m.end }])
      },
    }
  }

  private formatDuration(value: number) {
    const time = moment.duration(value).asSeconds() / 60
    const hours = Math.floor(time / 60)
    const minutes = Math.ceil(time % 60)
    return `${ hours } ${ this.translate.instant('hours') } ${ minutes } ${ this.translate.instant('minutes') }`
  }

  private addEventMarker(current: StatusEvent | undefined, next: StatusEvent | undefined, result: EventMarker[]) {
    let marker = this.createEventMarker(current, next)
    if (marker) result.push(marker)
  }
}

interface EventMarker {
  begin: string,
  end: string
}
