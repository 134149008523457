<mat-list class="flex-auto pt-0">
  @for (entry of entries; track entry) {
    <mat-list-item (click)="showDocument(entry)" class="document-item" *ngIf="!(isHandset$ | async)">
      <div class="flex flex-row items-center gap-2">
        @if (entry.hasVideo) {
          <div matTooltip="{{'Video' | translate}}">
            <mat-icon class="filetype-image">video_library</mat-icon>
          </div>
        } @else {
          <img [src]="getImage(entry)" class="filetype-image" alt="Document Filetype Image">
        }
        <div class="flex flex-col gap-1 flex-auto content-stretch">
          <div class="docu-link">
            @if (entry.title.length > 100) {
              <b [matTooltip]="entry.title">{{ entry.title | slice:0:100 }}...</b>
            } @else {
              <b>{{ entry.title }}</b>
            }
          </div>
          <div class="flex flex-row gap-2">
            <div
              class="basis-1/4 col-lg-2">{{ (Array.isArray(entry.lang)) ? entry.lang.join(" / ").toUpperCase() : entry.lang }}
            </div>
            <div class="basis-1/4 col-lg-2">{{ entry.code }}</div>
            <div class="basis-1/4 col-lg-2">V {{ entry.version }}</div>
            <div class="flex-auto"></div>
          </div>
        </div>
        <div class="col-2 flex flex-row content-end">
          <div class="document-type">{{ translateType(entry.type) }}</div>
        </div>
      </div>
    </mat-list-item>
    <mat-card *ngIf="(isHandset$ | async)">
      <mat-card-content (click)="showDocument(entry)">
        <div class="flex flex-row items-center gap-2">
          @if (entry.hasVideo) {
            <div matTooltip="{{'Video' | translate}}">
              <mat-icon class="filetype-image">video_library</mat-icon>
            </div>
          } @else {
            <img [src]="getImage(entry)" class="filetype-image" alt="Document Filetype Image">
          }
          <div class="flex flex-col gap-1 flex-auto content-stretch">
            <div class="docu-link">
              @if (entry.title.length > 100) {
                <b [matTooltip]="entry.title">{{ entry.title | slice:0:100 }}...</b>
              } @else {
                <b>{{ entry.title }}</b>
              }
            </div>
            <div>{{ (Array.isArray(entry.lang)) ? entry.lang.join(" / ").toUpperCase() : entry.lang }}</div>
            <div>{{ entry.code }}</div>
            <div>V {{ entry.version }}</div>
            <div class="document-type">{{ translateType(entry.type) }}</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-divider></mat-divider>
  }
</mat-list>

<mat-paginator [length]="totalSize"
               [pageSize]="pageSize"
               [pageIndex]="pageIndex"
               (page)="handlePaginatorEvent($event)"
               [pageSizeOptions]="[5, 10, 25, 100]"
               class="dense-1 pb-3" *ngIf="totalSize > 0 && totalSize > pageSize">
</mat-paginator>
