<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer [opened]="true" [style.width]="sidenavWidth()" class="sidenav"
               fixedInViewport
               mode="side">
    <app-main-menu (onCollapsed)="collapsed.set($event)"
                   class="flex flex-col h-full"></app-main-menu>
  </mat-sidenav>
  <mat-sidenav-content [style.margin-left]="sidenavWidth()">
    <div class="flex flex-col justify-start mw-100 min-h-screen">
      <app-main-header></app-main-header>
      <div class="flex-auto">
        <router-outlet></router-outlet>
      </div>
      <app-main-footer class="imprint"></app-main-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


<!--  <mat-sidenav-container class="sidenav-container">-->
<!--    <mat-sidenav #drawer class="sidenav" fixedInViewport-->
<!--                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"-->
<!--                 [mode]="(isHandset$ | async) ? 'over' : 'side'"-->
<!--                 [opened]="true">-->
<!--      <app-main-menu class="min-h-max"></app-main-menu>-->
<!--    </mat-sidenav>-->
<!--    <mat-sidenav-content>-->
<!--      <div class="flex flex-col justify-start mw-100 min-h-screen">-->
<!--        <mat-toolbar class="grow-0 shrink">-->
<!--          <button mat-icon-button-->
<!--                  class="menu-button"-->
<!--                  type="button"-->
<!--                  aria-label="Toggle sidenav"-->
<!--                  (click)="drawer.toggle()"-->
<!--                  matTooltip="{{'menu.ShowMenu' | translate}}"-->
<!--          >-->
<!--            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>-->
<!--          </button>-->

<!--          <div class="grow shrink flex flex-row flex-wrap gap-0 lg:gap-2 justify-between items-center content-center">-->
<!--          <span class="grow-0 shrink d-none lg:flex">-->
<!--            <img src="assets/img/logo.png" class="responsive-img" alt="" routerLink="{{ authService.isLoggedIn() ? '/site' : '/portal'}}">-->
<!--          </span>-->
<!--            <div class="grow shrink d-none sm:flex"></div>-->
<!--            <div class="grow shrink d-none sm:flex"></div>-->

<!--            <div class="grow-0 shrink d-none lg:flex">-->
<!--              <mat-form-field appearance="outline" class="custom-select-field-background dense-1" subscriptSizing="dynamic">-->
<!--                <mat-select [value]="lang" (selectionChange)="changeLang($event)" panelClass="mySelectedPanel">-->
<!--                  <mat-option value="en">English</mat-option>-->
<!--                  <mat-option value="de">Deutsch</mat-option>-->
<!--                  <mat-option value="et">Estonian</mat-option>-->
<!--                  <mat-option value="fr">French</mat-option>-->
<!--                  <mat-option value="it">Italian</mat-option>-->
<!--                  <mat-option value="ko">Korean</mat-option>-->
<!--                  <mat-option value="pt">Portuguese</mat-option>-->
<!--                  <mat-option value="ru">Russian</mat-option>-->
<!--                  <mat-option value="vi">Vietnamese</mat-option>-->
<!--                  <mat-option value="zh">Chinese</mat-option>-->
<!--                </mat-select>-->
<!--              </mat-form-field>-->
<!--            </div>-->

<!--            <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">-->
<!--              <mat-icon>account_circle</mat-icon>-->
<!--            </button>-->
<!--            <mat-menu #menu="matMenu">-->
<!--              @if (authService.isLoggedIn()) {-->
<!--                <button mat-menu-item routerLink="profile">-->
<!--                  <mat-icon>person</mat-icon>-->
<!--                  <span>{{ 'profile' | translate }}</span>-->
<!--                </button>-->
<!--                @if (authService.hasRole(AuthService.BETA_ACCESS)) {-->
<!--                  <button mat-menu-item routerLink="portal/wishlist">-->
<!--                    <mat-icon>star</mat-icon>-->
<!--                    <span>{{ 'wishlist.id' | translate }}</span>-->
<!--                  </button>-->
<!--                }-->
<!--                <button mat-menu-item (click)="authService.logout()">-->
<!--                  <mat-icon>logout</mat-icon>-->
<!--                  {{ 'menu.Logout' | translate }}-->
<!--                </button>-->
<!--              } @else {-->
<!--                <button mat-menu-item (click)="authService.login()">-->
<!--                  <mat-icon>login</mat-icon>-->
<!--                  {{ 'menu.Login' | translate }}-->
<!--                </button>-->
<!--              }-->
<!--            </mat-menu>-->

<!--            <app-cart-info class="grow-0 shrink d-none lg:flex"></app-cart-info>-->

<!--          </div>-->
<!--        </mat-toolbar>-->
<!--        <div class="main-content flex-auto">-->
<!--          <router-outlet></router-outlet>-->
<!--        </div>-->

<!--        <div class="imprint flex flex-row justify-end gap-2 p-2">-->
<!--          <a mat-button href="https://www.bitzer.de/gb/en/imprint/" target="_blank">{{ 'imprint' | translate }}</a>-->
<!--          <a mat-button href="https://www.bitzer.de/shared_media/documentation/myBITZER/Nutzungsbedingungen_BDN_en_Stand-Mai-2019.pdf" target="_blank">-->
<!--            {{ 'tos' | translate }}-->
<!--          </a>-->
<!--          <button mat-button (click)="openCookieSettings()">{{ 'cookieSettings' | translate }}</button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </mat-sidenav-content>-->
<!--  </mat-sidenav-container>-->
