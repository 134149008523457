<table mat-table [dataSource]="dataSource" matSort>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>
      <div class="flex flex-row gap-3 justify-start items-center">
        {{ 'description' | translate }}
      </div>
    </th>
    <td mat-cell *matCellDef="let entry">{{ entry.description }}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="code">
    <th mat-header-cell *matHeaderCellDef>{{ 'part' | translate }}</th>
    <td mat-cell *matCellDef="let entry">{{ parseInt(entry.code) }}</td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef>{{ 'order.item.net' | translate }}</th>
    <td mat-cell *matCellDef="let entry">
      {{ entry.price | currency: service.cart?.info?.currency }}
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef>{{ 'spareParts.quantity' | translate }}</th>
    <td mat-cell *matCellDef="let entry">
      <div class="flex flex-row justify-start items-center flex-auto">
        <mat-form-field class="flex dense-1" subscriptSizing="dynamic" appearance="outline">
          <input matInput type="number" min="1" value="{{entry.quantity}}" (change)="update(entry, $event)">
        </mat-form-field>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef> Total</td>
  </ng-container>

  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef>{{ 'total' | translate }}</th>
    <td mat-cell *matCellDef="let entry">{{ entry.totalPrice | currency: service.cart?.info?.currency }}
    </td>
    <td mat-footer-cell *matFooterCellDef>
      @if (service.cart) {
        {{ service.cart.info.totalPrice | currency: service.cart?.info?.currency }}
      }
    </td>
  </ng-container>


  <ng-container matColumnDef="cmd">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let entry">
      <div class="flex flex-row gap-3 justify-end items-center">
        <button mat-mini-fab (click)="delete(entry)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header"></tr>
  <tr mat-row *matRowDef="let info; columns: displayedColumns;" class="hover-effect"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
