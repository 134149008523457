import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperationalGraphRoutingModule } from './operational-graph-routing.module';
import { OperationalGraphComponent } from './operational-graph/operational-graph.component';
import { OperationalGraphHeaderComponent } from './operational-graph-header/operational-graph-header.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../../material/material.module";
import { NgxEchartsDirective } from "ngx-echarts";
import { OperationalGraphEquipmentEntryComponent } from './operational-graph-equipment-entry/operational-graph-equipment-entry.component';
import { OperationGraphPropertySelectorComponent } from './operation-graph-property-selector/operation-graph-property-selector.component';
import { OperationalGraphAddEquipmentDialogComponent } from "./operational-graph-add-equipment-dialog/operational-graph-add-equipment-dialog.component";
import { HierarchyModule } from "../../hierarchy/hierarchy.module";
import { PropertyModule } from "../../property/property.module";
import {
  OperationalGraphEquipmentListComponent
} from "./operational-graph-add-equipment-dialog/operational-graph-equipment-list/operational-graph-equipment-list.component";
import {
  OperationalGraphAddSystemComponent
} from "./operational-graph-add-equipment-dialog/operational-graph-add-system/operational-graph-add-system.component";


@NgModule({
  declarations: [
    OperationalGraphComponent,
    OperationalGraphHeaderComponent,
    OperationalGraphEquipmentEntryComponent,
    OperationGraphPropertySelectorComponent,
    OperationalGraphAddEquipmentDialogComponent,
    OperationalGraphEquipmentListComponent,
    OperationalGraphAddSystemComponent
  ],
  imports: [
    CommonModule,
    OperationalGraphRoutingModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxEchartsDirective,
    HierarchyModule,
    PropertyModule
  ]
})
export class OperationalGraphModule {
}
