import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import moment from "moment-timezone";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { System, SystemUpdateRequest } from "../model/system-api";
import { SystemService } from "../model/system.service";
import { HotToastService } from "@ngxpert/hot-toast";
import { TranslateService } from "@ngx-translate/core";
import { KnowledgeService } from "../../registration/model/knowledge.service";

@Component({
  selector: 'app-change-system-dialog',
  templateUrl: './change-system-dialog.component.html',
  styleUrls: ['./change-system-dialog.component.scss']
})
export class ChangeSystemDialogComponent {

  systemData = this.fb.group({
    name: [this.data?.name, Validators.required],
    type: [this.data?.type, Validators.required],
    refrigerant: [this.data?.refrigerant, Validators.required],
    timezone: [this.data?.timezone, Validators.required],
  })

  timeZones = moment.tz.names();
  refrigerants: string[] = []
  systemTypes: string[] | undefined

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: System,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChangeSystemDialogComponent>,
    private service: SystemService,
    private toastService: HotToastService,
    private translate: TranslateService,
    private knowledgeService: KnowledgeService,
  ) {
    this.knowledgeService.subject.subscribe(data => this.refrigerants = data)
  }

  ngOnInit(): void {
    this.systemData.get('timezone')?.setValue(this.data.timezone)
    this.refrigerants = this.knowledgeService.loadRefrigerants()
    this.systemTypes = this.knowledgeService.getSystemType()
  }

  update() {
    if (this.systemData.invalid) return
    let value = this.systemData.value
    let request = new SystemUpdateRequest(
      value.name ?? "",
      value.refrigerant ?? "",
      value.timezone ?? "",
      value.type ?? "",
    )
    if (request) {
      this.service.update(this.data.id, request).subscribe(s => {
        this.dialogRef.close(true)
      })

    }
  }
}
