import { Component } from '@angular/core';
import { EquipmentService } from "../../model/equipment.service";
import { ActivatedRoute } from "@angular/router";
import { ThingTemplateService } from "../../../thing-template/model/thing-template.service";
import { forkJoin, switchMap } from "rxjs";
import { OperationalGraphService } from "../model/operational-graph.service";
import { ModuleService } from "../../../module/model/module.service";
import moment from "moment";

@Component({
  selector: 'app-operational-graph',
  templateUrl: './operational-graph.component.html',
  styleUrl: './operational-graph.component.scss'
})
export class OperationalGraphComponent {


  reloading: boolean = false


  constructor(
    public service: OperationalGraphService,
    private equipmentService: EquipmentService,
    private thingTemplateService: ThingTemplateService,
    private moduleService: ModuleService,
    private route: ActivatedRoute,
  ) {
    this.service.reset()
    this.init()
  }

  private loadEquipment(id: number) {
    this.reloading = true
    forkJoin([
      this.equipmentService.getEquipment(id),
      this.thingTemplateService.getEquipmentTemplate(id),
      this.moduleService.findByEquipmentId(id)
    ])
      .pipe(
        switchMap(([equipment, template, modules]) => {
          this.service.addEntry(equipment, template)
          this.service.predefineProperties(modules.content)
          this.reloading = false
          return []
        })
      )
      .subscribe()
  }


  private changeChartsHeight() {
    const chartContainer = Array.from(document.getElementsByClassName('chart-container')).map(e => e as HTMLElement)
    chartContainer.map(e => e.style.height = '500px')
  }

  private init() {
    this.route.params.subscribe((params) => {
      const equipmentId = Number(params['id'])
      if (!equipmentId) return
      this.loadEquipment(equipmentId)
    })

    this.route.queryParams.subscribe(params => {
      const start = params['start']
      const end = params['end']

      if (!start && !end) return
      this.service.range.setValue({ start: moment(start), end: moment(end) })
    })

    this.service.changeEvent.subscribe(_  => {
      if (this.service.equipments.length >= 2) this.changeChartsHeight()
    })
  }
}
