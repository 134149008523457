import { EChartsOption } from "echarts";

export class InitialValueBuilder {

  buildPrimary(from: string, to: string): EChartsOption {
    return {
      animation: false,
      xAxis: {
        type: 'time',
        splitNumber: 15,
        splitLine: {
          show: true,
        },
        min: () => from,
        max: () => to,
        axisLabel: {
          hideOverlap: true
        }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      chart: {
        style: {
          cursor: 'crosshair',
        },
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          animation: false,
          type: 'cross',
        },
      },
      series: [{
        data: [],
        type: 'line'
      }],
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'filter',
          top: 30,
          fillerColor: 'rgba(58, 170, 53, 0.3)',
          moveHandleStyle: {
            color: 'rgba(58, 170, 53, 0.3)',
          },
        },
        {
          type: 'inside',
          xAxisIndex: [0],
        },
      ],
      grid: {
        top: 130,
        bottom: 50
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: false,
            brushStyle: {
              borderWidth: 1,
              color: 'rgba(120,140,180,0.3)',
              borderColor: 'rgba(120,140,180,0.8)',
            },
          },
          saveAsImage: {},
        }
      }
    }
  }

  buildSecondary(from: string, to: string): EChartsOption {
    return {
      animation: false,
      xAxis: {
        type: 'time',
        splitNumber: 15,
        splitLine: {
          show: true,
        },
        min: from,
        max: to,
        axisLabel: {
          hideOverlap: true
        }
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      chart: {
        style: {
          cursor: 'crosshair',
        },
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          animation: false,
          type: 'cross',
        },
      },
      series: [{
        data: [],
        type: 'line'
      }],
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'filter',
          show: false,
        },
        {
          type: 'inside',
          xAxisIndex: [0],
        },
      ],
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: false,
            brushStyle: {
              borderWidth: 1,
              color: 'rgba(120,140,180,0.3)',
              borderColor: 'rgba(120,140,180,0.8)',
            },
            icon: undefined
          },
        },
      },
      grid: {
        top: 50,
        bottom: 20
      }
    }
  }


}
