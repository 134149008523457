import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentRoutingModule } from './document-routing.module';
import { DocumentBoardComponent } from './document-board/document-board.component';
import { HeaderToolbarModule } from "../../component/header-toolbar/header-toolbar.module";
import { SearchModule } from "../../component/search/search.module";
import { DocumentProductDetailsComponent } from './document-product-details/document-product-details.component';
import { MaterialModule } from "../../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CategoryModule } from "../category/category.module";
import { DocumentBoardContentComponent } from './document-board-content/document-board-content.component';
import { DocumentBoardContentTypeSelectComponent } from './document-board-content-type-select/document-board-content-type-select.component';
import { DocumentBoardContentResultListComponent } from './document-board-content-result-list/document-board-content-result-list.component';
import { SparePartsModule } from "../spare-parts/spare-parts.module";
import { FeedbackModule } from "../feedback/feedback.module";


@NgModule({
  declarations: [
    DocumentBoardComponent,
    DocumentBoardContentComponent,
    DocumentBoardContentTypeSelectComponent,
    DocumentBoardContentResultListComponent,
    DocumentProductDetailsComponent,
  ],
  exports: [
    DocumentProductDetailsComponent,
    DocumentBoardContentComponent,
    DocumentBoardContentResultListComponent
  ],
  imports: [
    CommonModule,
    DocumentRoutingModule,
    HeaderToolbarModule,
    MaterialModule,
    SearchModule,
    SparePartsModule,
    TranslateModule,
    ReactiveFormsModule,
    CategoryModule,
    FeedbackModule,
  ]
})
export class DocumentModule {
}
