<app-header-toolbar [title]="productId" [showBack]="false">
  <app-header-toolbar-icon>
    <button mat-icon-button (click)="back()"
            matTooltip="{{'back' | translate}}"
    >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
  </app-header-toolbar-icon>
  <app-header-toolbar-content>

  </app-header-toolbar-content>
  <app-header-toolbar-actions>
    <button mat-icon-button [matMenuTriggerFor]="bookmarkMenu"
            matTooltip="{{'toolsServices' | translate}}">
      <mat-icon>bookmarks</mat-icon>
    </button>
  </app-header-toolbar-actions>
</app-header-toolbar>

<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>

<div class="flex flex-col gap-2 p-2">
  <app-product-details-tabs [data]="product"></app-product-details-tabs>
</div>


<mat-menu #bookmarkMenu="matMenu">
  <button mat-menu-item (click)="openBitzerSoftware()">
    <img [src]="bitzerSoftwareImage" class="menu-image" alt="Menu Image for the BITZER SOFTWARE">
  </button>
  <button mat-menu-item (click)="openEPartsSoftware()">
    <img [src]="epartsImage" class="menu-image" alt="Menu Image for EParts">
  </button>
  <button mat-menu-item (click)="openWebShop()">
    <img [src]="myBitzerShopImage" class="menu-image" alt="Menu Image for the MyBITZER Shop">
  </button>
</mat-menu>
