import { Injectable } from '@angular/core';
import {
  ConfigItemEntry,
  ConfigItemSelectEntry,
  ConfigSelectionType,
  CsticValueInfo,
  ProductConfigSearchRequest,
  ProductConfigSearchResult
} from "./product-config-api";
import {BehaviorSubject, Observable} from "rxjs";
import { ProductConfigService } from "./product-config.service";

@Injectable({
  providedIn: 'root'
})
export class ProductConfigSearchService {
  reloading$ = new BehaviorSubject<boolean>(false)
  selectedPidChanged$ = new BehaviorSubject<ProductConfigSearchResult | null>(null)

  searchObservable!: Observable<ProductConfigSearchResult>
  result!: ProductConfigSearchResult
  request: ProductConfigSearchRequest | undefined

  constructor(
    private service: ProductConfigService
  ) {
  }

  selectProduct(productId: string) {
    if (this.reloading$.value) return
    this.reloading$.next(true)
    this.searchObservable = this.service.searchDefault(productId);
    this.searchObservable.subscribe(d => this.handleData(d))
  }

  resetSelection() {
    if (!this.request) return
    this.request.entries = []
    this.updateRequest()
  }

  private updateRequest() {
    if (!this.request) return
    if (this.reloading$.value) return
    this.reloading$.next(true)
    this.searchObservable = this.service.search(this.request);
    this.searchObservable.subscribe(d => this.handleData(d))
  }

  selectConfigItem(c: ConfigItemEntry, o: CsticValueInfo) {
    if (!this.request) return
    const itemIndex = this.request.entries.findIndex(e => e.key == c.key)
    let entry = new ConfigItemSelectEntry(c.key, o.key, ConfigSelectionType.USER)
    if (itemIndex >= 0) {
      this.request.entries[itemIndex] = entry
    } else {
      this.request.entries.push(entry)
    }
    this.updateRequest()
  }

  unselectConfigItem(c: ConfigItemEntry) {
    if (!this.request) return
    const item = this.request.entries.find(e => e.key == c.key)
    if (!item) return
    item.selectionType = ConfigSelectionType.NO_SELECTION
    this.updateRequest()
  }

  private handleData(d: ProductConfigSearchResult): ProductConfigSearchResult {
    this.result = d
    this.request = d.request
    this.selectedPidChanged$.next(d)
    this.reloading$.next(false)
    return d
  }


}
