import { System } from "../../../system/model/system-api";
import { Site } from "../../../site/model/site-api";
import { ElectronicModule } from "../../../module/model/module-api";
import { Equipment } from "../../model/equipment-api";
import { EquipmentActivityReport } from "./operational-report-activity-api";
import { EquipmentLoadReport } from "./operational-report-load-api";
import { EquipmentStartsReport } from "./operational-report-starts-api";
import { EquipmentAlarmsReport } from "./operational-report-alarms-api";

export class EquipmentOperationalReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public status: EquipmentStatusReport,
    public activity: EquipmentActivityReport,
    public load: EquipmentLoadReport,
    public starts: EquipmentStartsReport,
    public alarms: EquipmentAlarmsReport
  ) { }
}

export class Period {
  constructor(
    public start: string,
    public end: string
  ) { }
}

export class EquipmentInfo {
  constructor(
    public equipment: Equipment,
    public system: System,
    public site: Site,
    public electronicModule: ElectronicModule
  ) { }
}

export class EquipmentOperationReportRequest {
  constructor(
    public from: string,
    public to: string,
    public lang: string,
    public propertyNames: string[],
    public meanPropertyNames: string[],
    public timezone: string
  ) { }
}

export class EquipmentStatusReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public status: string
  ) { }
}

export class Interval {
  constructor(
    public start: number,
    public end: number
  ) { }
}
