import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from "./error/page-not-found/page-not-found.component";
import { AuthGuard } from "./auth/auth.guard";
import {
  ADMIN,
  BETA_ACCESS,
  PROCESS_ADMIN,
  PUBLIC_ACCESS,
  SEARCH_ADMIN,
  SITE_ADMIN,
  SITE_READ,
  SITE_WRITE,
  STOCKLEVEL_IMPORT,
  SYSTEM_ADMIN,
  SYSTEM_READ,
  SYSTEM_WRITE
} from "./auth/roles";

let routes: Routes;
routes = [
  { path: '', redirectTo: 'site', pathMatch: 'full' },
  {
    path: 'process',
    title: 'Process',
    canActivate: [AuthGuard],
    data: { roles: [PROCESS_ADMIN] },
    loadChildren: () => import('./process/process.module').then(m => m.ProcessModule),
  },
  {
    path: 'accounting',
    title: 'BDN Accounting',
    canActivate: [AuthGuard],
    data: { roles: [] },
    loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule)
  },
  {
    path: 'export',
    title: 'Data Export',
    canActivate: [AuthGuard],
    loadChildren: () => import('./data-export/data-export.module').then(m => m.DataExportModule)
  },
  {
    path: 'admin',
    title: 'Administration',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: { roles: [ADMIN] }
  },
  {
    path: 'info',
    title: 'Info',
    loadChildren: () => import('./info/info.module').then(m => m.InfoModule)
  },
  {
    path: 'cart',
    title: 'Webshop Cart',
    canActivate: [AuthGuard],
    loadChildren: () => import('./commerce/commerce.module').then(m => m.CommerceModule)
  },
  {
    path: 'profile',
    title: 'Profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'site',
    title: 'BDN Site',
    canActivate: [AuthGuard],
    data: { roles: [SITE_READ, SITE_WRITE, SITE_ADMIN] },
    loadChildren: () => import('./site/site.module').then(m => m.SiteModule)
  },
  {
    path: 'system',
    title: 'BDN System',
    canActivate: [AuthGuard],
    data: { roles: [SYSTEM_READ, SYSTEM_WRITE, SYSTEM_ADMIN] },
    loadChildren: () => import('./system/system.module').then(m => m.SystemModule)
  },
  {
    path: 'equipment',
    title: 'BDN Equipment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule)
  },
  {
    path: 'search',
    title: 'BDN Search',
    canActivate: [AuthGuard],
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'registration',
    title: 'BDN Registration',
    canActivate: [AuthGuard],
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'company',
    title: 'Company',
    canActivate: [AuthGuard],
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'user',
    title: 'User',
    canActivate: [AuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'invite',
    title: 'Invite',
    canActivate: [AuthGuard],
    loadChildren: () => import('./invite/invite.module').then(m => m.InviteModule)
  },
  {
    path: 'access',
    title: 'BDN Access',
    canActivate: [AuthGuard],
    loadChildren: () => import('./access/access.module').then(m => m.AccessModule)
  },
  {
    path: 'portal',
    canActivate: [AuthGuard],
    title: 'MyBITZER Portal',
    data: { roles: [BETA_ACCESS, PUBLIC_ACCESS] },
    loadChildren: () => import('./portal/portal.module').then(m => m.PortalModule)
  },
  {
    path: 'backoffice',
    canActivate: [AuthGuard],
    title: 'MyBITZER Back Office',
    data: { roles: [SEARCH_ADMIN, STOCKLEVEL_IMPORT] },
    loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule)
  },
  {
    path: 'commerce',
    title: 'BDN Commerce',
    canActivate: [AuthGuard],
    loadChildren: () => import('./commerce/commerce.module').then(m => m.CommerceModule)
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
