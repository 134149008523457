@if (service.reloading$ | async) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

<div class="content-container">
  <div class="container-head flex w-full p-3 items-center border-bottom-accent">
    <h3 class="font-medium text-xl">{{ 'productConfig.title' | translate }}</h3>

    <div class="ml-auto flex gap-2">
      <button mat-stroked-button (click)="reset()" [disabled]="service.result && service.result.request.entries.length === 0">
        <mat-icon>restart_alt</mat-icon>
        {{ 'productConfig.resetSearch' | translate }}
      </button>
      
      <button mat-raised-button color="primary"
        [disabled]="service.result && (service.result.entries.length > 10  || service.result.entries.length < 2)"
        (click)="navigateToPidComparison()">
        {{ 'productConfig.compare' | translate }}
      </button>
    </div>
  </div>

  @if (!(service.reloading$ | async)) {
    <div class="board flex gap-2 p-2">
      <app-product-config-search-select class="w-2/4"></app-product-config-search-select>
      <app-product-config-search-result class="w-2/4"></app-product-config-search-result>
    </div>
  }
</div>
