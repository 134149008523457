<div class="flex flex-col lg:flex-row gap-2 p-2">
  <div class="flex flex-col gap-3">
    <app-category-select (selectionChanged)="handleCategorySelectionChanged($event)"
                         [relatedCategoryEntries]="categories"
                         *ngIf="categorySelectorVisible" class="col-lg-3">
    </app-category-select>

  </div>

  <div class="flex flex-col gap-2 flex-auto justify-start">
    <app-document-board-content-type-select
      (selectionChanged)="handleTypeSelectionChanged($event)"
      [selection]="request.documentTypes"
      [relatedTypeEntries]="types"
      *ngIf="typeSelectorVisible"
    >
    </app-document-board-content-type-select>

    <mat-progress-bar *ngIf="searching" mode="indeterminate"></mat-progress-bar>
    <mat-card>
      <mat-card-content class="p-0">
        <app-document-board-content-result-list [response]="response" (page)="handlePageEvent($event)">

        </app-document-board-content-result-list>
      </mat-card-content>
    </mat-card>

  </div>
</div>

<app-feedback-button title="feedback.document"></app-feedback-button>
