import {
  AfterContentInit,
  Component,
  computed,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {ProductSearchRequest, ProductSearchResponse} from "../model/product-api";
import {filter, mergeWith, Subscription} from "rxjs";
import {CategorySelectComponent} from "../../category/category-select/category-select.component";
import {ProductService} from "../model/product.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {BreakpointObserver} from "@angular/cdk/layout";
import {PageEvent} from "@angular/material/paginator";
import {AsyncPipe, CommonModule} from "@angular/common";
import {MaterialModule} from "../../../material/material.module";
import {CategoryModule} from "../../category/category.module";
import {
  ProductBoardContentResultListComponent
} from "../product-board-content-result-list/product-board-content-result-list.component";
import {
  FilterGroup,
  FilterGroupComponent
} from "../../../component/filter/filter-group/filter-group.component";
import {ProductFacadeService, ProductState} from "../model/product-facade.service";
import {
  FilterGroupListComponent
} from "../../../component/filter/filter-group-list/filter-group-list.component";
import {MatOption} from "@angular/material/autocomplete";
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop";
import {map, tap} from "rxjs/operators";

let DEFAULT_PAGE_SIZE = 25;

@Component({
  selector: 'app-product-board-content',
  templateUrl: './product-board-content.component.html',
  styleUrl: './product-board-content.component.scss',
  standalone: true,
  imports: [CommonModule, MaterialModule, TranslateModule, CategoryModule, ProductBoardContentResultListComponent, FilterGroupComponent, FilterGroupListComponent, AsyncPipe]
})
export class ProductBoardContentComponent implements OnInit, OnDestroy, AfterContentInit {


  @Input() categorySelectorVisible: boolean = true;
  @Input() loadOnStartup: boolean = true;

  @Output() reset = new EventEmitter<boolean>();
  @Output() requestChanged = new EventEmitter<ProductSearchRequest>();

  searching: boolean = false;
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageIndex: number = 0;
  totalSize: number = -1;

  response = signal<ProductSearchResponse | null>(null);
  filterListTitleSuffix = computed(() => this.response()?.entries.totalSize ? `(${this.response()?.entries.totalSize} Products)` : '');
  state = this.productFacadeService.productState;
  request: ProductSearchRequest = new ProductSearchRequest([], [], [], '', this.translate.currentLang);
  @ViewChild(CategorySelectComponent) categorySelect: CategorySelectComponent | undefined;
  private state$ = toObservable(this.state)
  .pipe(
    filter((resp) => !!resp),
  );
  computedResponse = toObservable(this.response)
  .pipe(
    filter((resp): resp is ProductSearchResponse => !!resp),
    mergeWith(this.state$.pipe(map(state => state?.productResponse),)),
  );
  private subscriptions: Subscription[] = [];

  constructor(private service: ProductService,
              private productFacadeService: ProductFacadeService,
              private translate: TranslateService,
              private breakpoint: BreakpointObserver
  ) {
    this.state$.pipe(
      tap((state) => this.handleCategorySelectionChanged(state)),
      takeUntilDestroyed()
    ).subscribe();
  }

  ngOnInit() {
    let s1 = this.translate.onLangChange.subscribe(evt => {
      this.request.lang = evt.lang;
      this.handleRequestChanged();
    });
    this.productFacadeService.loadRootCategories().subscribe();
    this.subscriptions = [s1];
  }

  ngAfterContentInit() {
    if (this.loadOnStartup) this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.load();
  }

  search(query: string) {
    this.request.fullTextSearch = query;
    this.handleRequestChanged();
  }

  updateRequest(query: string, technologyIds: string[], selectedApplicationId: string, selectedTechnologyId: string, seriesIds: string[]) {
    this.request.fullTextSearch = query;
    this.request.technologyIds = technologyIds;
    this.request.selectedApplicationId = selectedApplicationId;
    this.request.selectedTechnologyId = selectedTechnologyId;
    this.request.categoryIds = [selectedApplicationId, selectedTechnologyId].filter(item => item.length > 0);
    this.request.seriesIds = seriesIds;
    if (this.categorySelect) {
      this.categorySelect.updateRequest(this.request, '');
    }
    this.load();
  }

  onFilterSelected(event: MatOption) {
    const {value} = event;
    const payload = value.value;
    payload.selectedValue = value;

    this.productFacadeService.updateState(payload).subscribe();
  }

  onFilterCleared(payload: any) {
    this.productFacadeService.clearState(payload).subscribe();
  }

  onFiltersReset() {
    this.productFacadeService.loadRootCategories().subscribe();
  }

  handleCategorySelectionChanged(event: ProductState | undefined) {
    if (event && event.filterGroups?.length) {
      const applicationId = this.getSelectedValue(event.filterGroups[0])!;
      const technologyId = this.getSelectedValue(event.filterGroups[1])!;
      this.request.selectedApplicationId = applicationId;
      this.request.selectedTechnologyId = technologyId;

      this.request.categoryIds = [applicationId, technologyId];
    }
  }


  private getSelectedValue(group: FilterGroup[]) {

    return group.slice(-1)[0].groupId;
  }

  private load(overwrite: boolean = false) {
    if (this.searching && !overwrite) return;
    this.searching = true;

    this.service.searchProducts(this.request, this.pageIndex, this.pageSize)
    .subscribe(d => this.handleData(d.body));
  }

  private handleData(response: ProductSearchResponse | null) {
    if (!response) return false;
    this.response.set(response);
    this.pageIndex = response.entries.pageable.number;
    this.totalSize = response.entries.totalSize;
    this.searching = false;
    return true;
  }

  private handleRequestChanged() {
    this.pageIndex = 0;
    this.requestChanged.emit(this.request);
    this.load();
  }
}
