import {Component, computed, signal} from '@angular/core';
import {Observable} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {map, shareReplay} from "rxjs/operators";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  collapsed = signal(false);
  sidenavWidth = computed(() => (this.collapsed() ? '85px' : '300px'));

  isHandset$: Observable<boolean> = this.breakpoint.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(private breakpoint: BreakpointObserver) {
  }
}
