import { EquipmentInfo, Interval, Period } from "./operational-report-api";
import { Alarm } from "../../../alarm/model/alarm-api";
import { EnvelopePoint } from "../../../property/model/property-api";
import { PropertyDefinition } from "../../../thing-template/model/thing-template-api";

export class EquipmentActivityReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public running: EquipmentRunningTimeReport,
    public heatmap: EquipmentPropertyHeatMapReport,
    public runtime: EquipmentRuntimeDistributionReport,
    public values: MeanPropertySeries[]
  ) { }
}

export class EquipmentRunningTimeReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public operatingHours: number,
    public entries: EquipmentRunningTimeEntry[],
    public alarms: Alarm[]
  ) { }
}

export class EquipmentRunningTimeEntry {
  constructor(
    public start: string,
    public stop: string,
    public type: number
  ) { }
}

export class EquipmentPropertyHeatMapReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public envelope: ReportEnvelope,
    public x: PropertyDefinition,
    public y: PropertyDefinition,
    public entries: PropertyHeatmapEntry[]
  ) { }
}

export class ReportEnvelope {
  constructor(
    public points: EnvelopePoint[] ,
    public type: string,
    public thingId: number
  ) {
  }
}

export class PropertyHeatmapEntry {
  constructor(
    public x: Interval,
    public y: Interval,
    public percentage: number,
    public value: number
  ) { }
}

export class EquipmentRuntimeDistributionReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public meanValue: number,
    public intervalUnit: string,
    public entry: RuntimeDistributionEntry[]
  ) { }
}

export class RuntimeDistributionEntry {
  constructor(
    public interval: Interval,
    public percentage: number,
    public value: number
  ) { }
}

export class MeanPropertySeries {
  constructor(
    public definition: PropertyDefinition,
    public averageValue: number,
    public minimumValue: number,
    public maximumValue: number,
    public entries: MeanPropertySeriesEntry[]
  ) { }
}

export class MeanPropertySeriesEntry {
  constructor(
    public timestamp: string,
    public value: number
  ) { }
}

export class HeatmapEntry {
  constructor(
    public x: number,
    public y: number,
    public val: number
  ) {
  }
}

export class EnvelopeRange {
  constructor(
    public xMin: number,
    public xMax: number,
    public yMin: number,
    public yMax: number,
    public siUnit: string,
  ) {
  }
}
