import { Equipment } from "../../model/equipment-api";
import { SeriesOption } from "echarts";
import { Page } from "../../../utils/page";
import { Observable, Subject } from "rxjs";

export abstract class BaseLoader<T> {

  protected readonly PAGE_SIZE: number = 1000

  load(equipment: Equipment, from: string, to: string): Observable<LoaderCallback> {
    let data: T[] = []
    let subject = new Subject<LoaderCallback>()
    this.loadPage(equipment, from, to, 0, data, subject)
    return subject.asObservable()
  }

  protected abstract loadPage(equipment: Equipment, from: string, to: string, page: number, data: T[], callback: Subject<LoaderCallback>): void

  protected handlePage(equipment: Equipment, from: string, to: string, page: number, data: T[], callback: Subject<LoaderCallback>, d: Page<T>) {
    data.push(...d.content)
    let hasNextPage = d.totalPages > (page + 1)
    if (hasNextPage) {
      this.loadPage(equipment, from, to, page + 1, data, callback)
    } else {
      this.processData(data, callback)
    }
  }

  protected abstract processData(data: T[], callback: Subject<LoaderCallback>): void

}

export interface LoaderCallback {
  primary: SeriesOption[]
  secondary: SeriesOption[]
}

