<app-header-toolbar *ngIf="!productDetailsRoute" [title]="product?.id">
  <app-header-toolbar-icon>

  </app-header-toolbar-icon>
  <app-header-toolbar-content>

  </app-header-toolbar-content>
  <app-header-toolbar-actions>

  </app-header-toolbar-actions>
</app-header-toolbar>

<mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>

<div class="flex flex-col gap-2 p-2">
  @if (product) {
    <app-document-board-content [categorySelectorVisible]="false" [productId]="product.id"></app-document-board-content>
  }
</div>



