import { Component, ViewChild } from '@angular/core';
import { SeriesService } from "../model/series.service";
import { ActivatedRoute, Params } from "@angular/router";
import { SeriesInfo } from "../model/series-api";
import { ProductBoardContentComponent } from "../../product/product-board-content/product-board-content.component";

@Component({
  selector: 'app-series-details-products',
  templateUrl: './series-details-products.component.html',
  styleUrl: './series-details-products.component.scss'
})
export class SeriesDetailsProductsComponent {

  reloading: boolean = false
  @ViewChild(ProductBoardContentComponent) content: ProductBoardContentComponent | undefined

  constructor(
    private service: SeriesService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => this.handleUrlParamsChanged(params))
  }

  private handleUrlParamsChanged(params: Params) {
    let seriesId = params.series ?? ''
    this.reloading = true
    this.service.getSeries(seriesId).subscribe(d => this.handleData(d))
  }

  private handleData(d: SeriesInfo) {
    if (this.content) this.content.updateRequest('', [], '', '', [d.id])
    this.reloading = false
  }


}
