import { Component, Input } from '@angular/core';
import { AuthService } from "../../../auth/auth.service";
import { ConfigItemEntry, ConfigSelectionType, ProductConfigIdInfo } from "../model/product-config-api";
import { WishlistAddEntry, WishlistAddEntryDialogComponent } from "../../wishlist/wishlist-add-entry-dialog/wishlist-add-entry-dialog.component";
import { WishlistService } from "../../wishlist/model/wishlist.service";
import { MatDialog } from "@angular/material/dialog";
import { WishlistEntry } from "../../wishlist/model/wishlist-api";
import { COMMERCE_ADMIN, COMMERCE_READ } from "../../../auth/roles";

@Component({
  selector: 'app-product-config-search-result-panel',
  templateUrl: './product-config-search-result-panel.component.html',
  styleUrl: './product-config-search-result-panel.component.scss'
})
export class ProductConfigSearchResultPanelComponent {
  @Input() prices: Record<string, number> = {}
  @Input() entries: ProductConfigIdInfo[] = []
  @Input() items: ConfigItemEntry[] = []
  @Input() wishlistActive: WishlistEntry[] = []
  displayedColumns = ['id']

  constructor(
    private authService: AuthService,
    private wishlistService: WishlistService,
    private dialog: MatDialog,
  ) {
  }

  isUserSelectedValue(i: any): boolean {
    let selection = this.items.find(e =>
      e.selectionType == ConfigSelectionType.USER
      && e.selectedValue?.key == i.value.key
    )
    return selection != null
  }

  hasCommerceReadPermissions(): boolean {
    return this.authService.hasRole(COMMERCE_READ) || this.authService.hasRole(COMMERCE_ADMIN)
  }

  addToWishList(entry: ProductConfigIdInfo) {
    if (entry) {
      const ref = this.dialog.open(WishlistAddEntryDialogComponent, {
        width: '50%',
        data: {
          entry: new WishlistAddEntry(entry.id, entry.type),
          entries: this.entries.map(e => new WishlistAddEntry(e.id, e.type))
        }
      });
      ref.afterClosed().subscribe(_ => this.updateWishlistActive())
    }
  }

  private updateWishlistActive() {
    const references = this.entries.map(entry => entry.id)
    if (references.length) this.wishlistService.checkWishlist(references).subscribe(data => {
      this.wishlistActive = data
    })
  }

  isWishlistActive(entry: ProductConfigIdInfo) {
    if (!this.wishlistActive) return false
    const active = this.wishlistActive.find(e => parseInt(e.reference) == parseInt(entry.id))
    return active != undefined
  }
}
