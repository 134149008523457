import { Component } from '@angular/core';
import { ProductConfigSearchService } from "../model/product-config-info.service";
import {ConfigSelectionType} from "../model/product-config-api";

@Component({
  selector: 'app-product-config-search-select',
  templateUrl: './product-config-search-select.component.html',
  styleUrl: './product-config-search-select.component.scss'
})
export class ProductConfigSearchSelectComponent {

  constructor(public service: ProductConfigSearchService) {
  }

  protected readonly ConfigSelectionType = ConfigSelectionType;
}
