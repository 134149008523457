<div class="flex flex-col lg:flex-row gap-3 p-2">
  <div class="flex flex-col gap-2 justify-start basis-1/2 h-100">
    <mat-progress-bar *ngIf="searching" mode="indeterminate"></mat-progress-bar>
    <app-spare-parts-board-content-result-list class="h-100" [response]="response" [selected]="selected" (page)="handlePageEvent($event)"
                                               (selectionChanged)="handleSelectionChanged($event)">
    </app-spare-parts-board-content-result-list>
  </div>

  <div class="flex flex-col gap-2 flex-auto justify-start flex-auto">
    <app-spare-parts-board-content-details></app-spare-parts-board-content-details>
  </div>
</div>
