import { Injectable } from '@angular/core';
import { ConsentService } from "../../consent/consent.service";
import { MainHeaderAction } from "./main-header-action";
import { MainMenuAction, MainMenuActionGroup } from "./main-menu-action";
import packageJson from "../../../../package.json";
import { Principal } from "../../auth/principal";
import { HEADER_ACTIONS, MENU_ACTIONS } from "./config";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public version: string = packageJson.version;
  menuActions: MainMenuActionGroup[] = []
  headerActions: MainHeaderAction[] = []
  customHeaderActions: MainHeaderAction[] = []

  private searchSubject = new Subject<string>()
  search = this.searchSubject.asObservable()
  query: string = ''
  searchVisible: boolean = false


  constructor(
    private consentService: ConsentService,
  ) {
  }

  openCookieSettings() {
    this.consentService.openCookieSettings()
  }

  handlePrincipalChanged(principal: Principal | null) {
    this.menuActions = MENU_ACTIONS.map(g => this.updateGroupAccessibility(principal, g))
    this.headerActions = HEADER_ACTIONS.filter(a => this.isHeaderActionAccessible(principal, a))
  }

  private updateGroupAccessibility(principal: Principal | null, g: MainMenuActionGroup): MainMenuActionGroup {
    let actions = g.actions.filter(a => this.isMenuActionAccessible(principal, a))
    return { name: g.name, actions }
  }

  private isMenuActionAccessible(principal: Principal | null, a: MainMenuAction): boolean {
    if (a.roles.length == 0) return true
    if (!principal) return false
    return a.roles.find(role => principal.roles.find(r => r == role) != null) != null
  }

  private isHeaderActionAccessible(principal: Principal | null, a: MainHeaderAction): boolean {
    if (a.roles.length == 0) return true
    if (!principal) return false
    return a.roles.find(role => principal.roles.find(r => r == role) != null) != null
  }

  executeSearch(query: string) {
    this.query = query
    this.searchSubject.next(query)
  }
}
