<mat-card class="m-3">
  <form class="search">
    <mat-form-field class="search-input" appearance="outline">
      <mat-label>{{ 'products.filter.Search'  | translate }}</mat-label>
      <input type="search"
             [placeholder]="'products.filter.Search' | translate"
             matInput
             [formControl]="search"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        @for (property of filteredProperties; track property) {
          <mat-option>
            <div class="flex justify-between" [ngClass]="isSmallScreen ? 'option-small' : 'option-large'">
              <div> {{ trimPropertyName(property.name) | translate }}</div>
              <mat-slide-toggle color="primary"
                                (change)="handlePropertySelectionChanged($event, property)"
                                [checked]="selectedProperties.indexOf(property) !== -1">
              </mat-slide-toggle>
            </div>
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <mat-accordion multi="true">
    <ng-container *ngFor="let category of categories">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <div class="flex flex-row gap-4 justify-between items-center">
            <mat-checkbox [color]="getCheckboxColor(category)" (change)="selectCategory(category)"
                          (click)="$event.stopPropagation()"
                          [checked]="getCheckboxState(category)"></mat-checkbox>
            <div>{{ category.name }}</div>
          </div>
        </mat-expansion-panel-header>
        <ng-container>
          <mat-list *ngFor="let property of category.properties">
            <mat-list-item>
              <div class="flex flex-row gap-4 justify-between items-center">
                <div> {{ trimPropertyName(property.name) | translate }}</div>
                <mat-slide-toggle color="primary" (change)="handlePropertySelectionChanged($event, property)"
                                  [checked]="selectedProperties.indexOf(property) !== -1"></mat-slide-toggle>
              </div>
            </mat-list-item>
          </mat-list>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</mat-card>

<div class="flex content-end gap-2 mx-3 my-2">
  <button mat-stroked-button
          color="primary"
          style="background-color: #272727; color: white"
          (click)="onSaveClick()">
    {{ 'action.Save' | translate }}
  </button>
  <button mat-stroked-button
          color="primary"
          (click)="onCancelClick()">
    {{ 'cancel' | translate }}
  </button>
</div>

