<div class="report-container">
  <app-operational-report-header></app-operational-report-header>

  <mat-progress-bar *ngIf="reloading" mode="indeterminate"></mat-progress-bar>

  @if(report) {
    <div class="flex gap-40 mb-7">
      <app-operational-report-equipment-info [reportInfoData]="report?.info"
                                             class="container-width">
      </app-operational-report-equipment-info>

      <app-operational-report-equipment-status [statusData]="report?.status"
                                               class="container-width">
      </app-operational-report-equipment-status>
    </div>

    <app-operational-report-equipment-activity [reportData]="report"></app-operational-report-equipment-activity>

    <app-operational-report-equipment-load [loadData]="report?.load"></app-operational-report-equipment-load>

    <app-operational-report-equipment-starts [startsData]="report?.starts"></app-operational-report-equipment-starts>

    <app-operational-report-equipment-alarms
      [alarmReportData]="report?.alarms"></app-operational-report-equipment-alarms>
  } @else {
    <div class="flex justify-center m-3">
      {{ 'operationgraph.noDataText' | translate }}
    </div>
  }
</div>
