@if (this.hasCommerceReadPermissions()) {
  @if (mode === 'fab') {
    <button mat-mini-fab (click)="addToCart()" [disabled]="reloading">
      @if (reloading) {
        <mat-icon>
          <mat-spinner diameter="18" mode="indeterminate"></mat-spinner>
        </mat-icon>
      } @else {
        <mat-icon>add_shopping_cart</mat-icon>
      }
    </button>
  } @else {
    <button mat-flat-button (click)="addToCart()" [disabled]="reloading" color="primary">
      @if (reloading) {
        <mat-icon>
          <mat-spinner diameter="18" mode="indeterminate"></mat-spinner>
        </mat-icon>
      } @else {
        <mat-icon>add_shopping_cart</mat-icon>
      }
      {{ 'cart.add' | translate }}
    </button>
  }
}
