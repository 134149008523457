<h2 class="font-medium text-2xl">{{ groupTitle() }}</h2>
@if (filters()?.length) {
  <div class="filter-group">
    @for (filter of filters(); track filter.name) {
      <app-filter [filter]="filter" (filterSelected)="filterSelected.emit($event)"
                  (filterCleared)="filterCleared.emit($event)"></app-filter>
    }
  </div>

}
