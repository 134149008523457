<form class="example-form">
  <mat-form-field appearance="outline" class="w-96 dense-1">
    <mat-label class="text-base">
      <span>
         {{ filter().name }}
      </span>
      <span>({{ filter().nameSuffix }})</span></mat-label>
    <input [formControl]="control"
           [matAutocomplete]="auto"
           aria-label="Bla"
           matInput
           type="text">
    <mat-autocomplete #auto="matAutocomplete"
                      (optionSelected)="filterSelected.emit($event.option)"
                      [displayWith]="displayFn">
      @for (option of filter().options; track option) {
        <mat-option class="custom-option" [value]="option">
          <span>
          {{ option.displayValue }}
        </span>
          @if (option.displayValueSuffix) {
            <span class="badge">{{
                option.displayValueSuffix ?? ''
              }}</span>
          }
        </mat-option>
      }
    </mat-autocomplete>
    <div matSuffix>
      @if (control.value) {
        <button aria-label="Example icon button with a open in new tab icon"
                mat-icon-button (click)="clear($event)">
          <mat-icon>close</mat-icon>
        </button>
      }
    </div>

  </mat-form-field>
</form>
