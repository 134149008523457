import { Component, Input } from '@angular/core';
import { EnvelopePoint } from "../../../property/model/property-api";
import { ECharts, EChartsOption, LegendComponentOption, SeriesOption } from "echarts";
import { XAXisOption, YAXisOption } from "echarts/types/dist/shared";
import { EnvelopeRange, HeatmapEntry, ReportEnvelope } from "../model/operational-report-activity-api";

@Component({
  selector: 'app-operational-envelope',
  templateUrl: './operational-envelope.component.html',
  styleUrl: './operational-envelope.component.scss'
})
export class OperationalEnvelopeComponent {

  @Input()
  set envelope(data: ReportEnvelope | undefined) {
    if (!data) return
    this.processEnvelope(data)
  }

  @Input()
  set heatmap(data: HeatmapEntry[]) {
    if (!data) return
    this.setHeatmap(data)
  }

  @Input()
  set series(data: SeriesOption[]) {
    if (!data) return
    this.setSeries(data)
  }


  private xAxis: XAXisOption[] = []
  private yAxis: YAXisOption[] = []
  private axis: EnvelopeRange | undefined

  seriesOptions: SeriesOption[] = []
  chart: ECharts | undefined
  initialValue: EChartsOption | null
  dynamicData: EChartsOption | null
  legend: LegendComponentOption = this.initLegend()
  loading = false

  constructor() {
    this.initialValue = {}
    this.dynamicData = null
  }

  private processEnvelope(info: ReportEnvelope) {
    if (!info) return
    this.clearData()

    const envelope = this.getLineSettings(info.points, 'black', 2)
    this.seriesOptions.push(envelope)
    this.setEnvelopeAxisValues(info)
    this.initialValue = {
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      series: this.seriesOptions,
      legend: this.legend
    }
  }

  private getLineSettings(points: EnvelopePoint[], color: string, width: number): SeriesOption {
    let data = points.concat(points[0]).map(p => [p.x, p.y])
    return {
      name: 'Envelope',
      type: 'line',
      data: data,
      showSymbol: false,
      lineStyle: {
        color: color,
        width: width
      }
    }
  }

  private setEnvelopeAxisValues(data: ReportEnvelope) {
    const xPoints = data.points.map(point => point.x)
    const yPoints = data.points.map(point => point.y)
    this.axis = new EnvelopeRange(
      minMax({input: xPoints, val: -10}),
      minMax({input: xPoints, val: +10}),
      minMax({input: yPoints, val: -10}),
      minMax({input: yPoints, val: +10}),
      data.type,
    )
    this.xAxis.push({
      min: this.axis.xMin,
      max: this.axis.xMax,
      show: false,
      type: "value",
    })
    this.yAxis.push({
      min: this.axis.yMin,
      max: this.axis.yMax,
      show: false,
      type: "value",
    })
  }


  private setHeatmap(data: HeatmapEntry[]) {
    if (!data || !this.axis) return
    const xRange = range({from: this.axis?.xMin, to: this.axis?.xMax, step: 5.0})
    const yRange = range({from: this.axis?.yMin, to: this.axis?.yMax, step: 5.0})

    this.setHeatmapSeries(data, xRange, yRange)
    this.setHeatmapAxis(xRange, yRange)

    const maxValue = Math.max(...data.map(o => o.val), 0)
    this.dynamicData = {
      visualMap: {
        right: 0,
        top: 1,
        orient: 'vertical',
        min: 0,
        max: maxValue,
        show: maxValue > 0,
        calculable: false,
        precision: 2,
        formatter: '{value} h',
        inRange: {
          color: ['#e6f1dd', '#3aaa35', '#050d15'],
        },
      },
      tooltip: {
        formatter: (obj: any) => {
          const siUnit = this.axis?.siUnit === 'CELSIUS' ? '°C' : 'bar'
          const [x, y, val] = obj?.value
          let xVal = xRange[x]
          let yVal = yRange[y]
          return `
                To: ${xVal} ${siUnit} to
                ${(xVal + 5)} ${siUnit} <br>
                Tc: ${yVal} ${siUnit} to
                ${(yVal + 5)} ${siUnit} <br>
                Time: ${Math.round(val * 100) / 100} h
              `;
        },
      },
      xAxis: this.xAxis,
      yAxis: this.yAxis,
      series: this.seriesOptions
    }
  }

  private setHeatmapSeries(data: HeatmapEntry[], xRange: number[], yRange: number[]) {
    const entries = data.map(it => {
      return [xRange.indexOf(it.x), yRange.indexOf(it.y), it.val]
    })
    const heatmapSeries: SeriesOption = {
      xAxisIndex: 1,
      yAxisIndex: 1,
      name: 'HeatMap',
      type: 'heatmap',
      data: entries,
      label: {
        show: false
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
    this.seriesOptions.push(heatmapSeries)
  }

  private setHeatmapAxis(xRange: number[], yRange: number[]) {
    if (!this.axis) return
    const siUnit = this.axis.siUnit === 'CELSIUS' ? '°C' : 'bar'
    this.xAxis.push({
      name: siUnit,
      splitArea: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
      axisLabel: {
        interval: (idx, _) => idx % 2 == 0,
      },
      axisLine: {
        onZero: false,
      },
      position: 'bottom',
      type: "category",
      data: xRange
    })
    this.yAxis.push({
      name: siUnit,
      splitArea: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
      axisLabel: {
        interval: (idx, _) => idx % 2 == 0,
      },
      axisLine: {
        onZero: false,
      },
      type: "category",
      position: 'left',
      data: yRange
    })
  }

  private setSeries(data: SeriesOption[]) {
    if (!data.length || !this.axis) return

    const siUnit = this.axis.siUnit === 'CELSIUS' ? '°C' : 'bar'
    const legendData = data.map(d => d.name as string)

    this.legend.data?.push(...legendData)

    this.xAxis.push({
      min: this.axis.xMin,
      max: this.axis.xMax,
      position: "bottom",
      type: "value",
      axisLine: {
        onZero: false
      },
      name: 'To[' + siUnit + ']',
    })
    this.yAxis.push({
      min: this.axis.yMin,
      max: this.axis.yMax,
      position: "left",
      type: "value",
      axisLine: {
        onZero: false
      },
      name: 'Tc[' + siUnit + ']',
    })
    this.seriesOptions.push(...data)
  }

  private clearData() {
    this.xAxis = []
    this.yAxis = []
    this.seriesOptions = []
  }

  private initLegend(): LegendComponentOption {
    return {
      orient: 'vertical',
      right: -5,
      top: 'center',
      itemHeight: 10,
      textStyle: {
        fontSize: 10
      },
      data: []
    }
  }
}

const minMax = ({input = [0], val = 0}) => {
  if (val > 0) {
    return Math.ceil((Math.max(...input) + val) / 10) * 10
  } else {
    return Math.floor((Math.min(...input) + val) / 10) * 10
  }
}
const range = ({from = 0.0, to = 100.0, step = 1.0, length = Math.ceil(((to + step) - from) / step)}) =>
  Array.from({length}, (_, i) => from + i * step)
