import {Alarm, AlarmInfo} from "../../../alarm/model/alarm-api";
import { EquipmentInfo, Period } from "./operational-report-api";
import { ReportEnvelope } from "./operational-report-activity-api";
import {PropertyDouble} from "../../../property/model/property-api";

export class EquipmentAlarmsReport {
  constructor(
    public period: Period,
    public info: EquipmentInfo,
    public last: EquipmentLastAlarms,
    public graph: EquipmentAlarmsGraph,
    public stats: EquipmentAlarmStats,
    public distribution: EquipmentAlarmDistribution
  ) { }
}

export class EquipmentLastAlarms {
  constructor(
    public alarms: AlarmInfo[]
  ) { }
}

export class EquipmentAlarmStats {
  constructor(
    public entries: EquipmentAlarmStatsEntry[]
  ) { }
}

export class EquipmentAlarmStatsEntry {
  constructor(
    public level: string,
    public code: number,
    public description: string,
    public amount: number,
    public percentage: number,
    public alarms: AlarmInfo[]
  ) { }
}

export class EquipmentAlarmDistribution {
  constructor(
    public entries: Map<string, EquipmentAlarmDistributionEntry[]>
  ) { }
}

export class EquipmentAlarmDistributionEntry {
  constructor(
    public start: string,
    public end: string,
    public alarms: AlarmInfo[]
  ) { }
}

export class EquipmentAlarmsGraph {
  constructor(
    public envelope: ReportEnvelope,
    public entries: EquipmentAlarmsGraphEntry[]
  ) { }
}

export class EquipmentAlarmsGraphEntry {
  constructor(
    public alarm: Alarm,
    public properties: PropertyDouble[]
  ) { }
}

export class AlarmDistributionTableEntry {
  constructor(
    public time: string,
    public alarms: AlarmInfo[][],
  ) { }
}

export class AlarmDistributionLevel {
  constructor(
    public code: number,
    public level: string,
    public amount: number,
  ) { }
}
