import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Subscription } from "rxjs";
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from "@angular/router";
import { SeriesBoardContentComponent } from "../series-board-content/series-board-content.component";
import { SeriesSearchRequest } from "../model/series-api";
import { HomeService } from "../../../home/model/home.service";

@Component({
  selector: 'app-series-board',
  templateUrl: './series-board.component.html',
  styleUrl: './series-board.component.scss'
})
export class SeriesBoardComponent {


  private subscriptions: Subscription[] = []
  @ViewChild(SeriesBoardContentComponent) content: SeriesBoardContentComponent | undefined

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private home: HomeService,
  ) {
  }

  ngOnInit() {
    this.home.searchVisible = true
  }

  ngAfterViewInit() {
    let s2 = this.route
      .queryParamMap
      .subscribe(value => this.handleUrlParamsChanged(value))

    let s3 = this.home.search.subscribe(query => {
        if (this.content) this.content.search(query)
      }
    )

    this.subscriptions = [s2, s3]
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []

    this.home.customHeaderActions = []
    this.home.searchVisible = false
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }


  private handleUrlParamsChanged(value: ParamMap) {
    let query = value.get('q') ?? ""
    let ca = value.get('ca') ?? ''
    let ct = value.get('ct') ?? ''

    if (this.content) this.content.updateRequest(query, ca, ct)
  }

  protected handleRequestChanged(request: SeriesSearchRequest) {
    let query = (request.fullTextSearch.length > 0) ? request.fullTextSearch : undefined
    const navigationExtras: NavigationExtras = {
      queryParams: {
        q: query,
        ca: (request.selectedApplicationId.length > 0) ? request.selectedApplicationId : undefined,
        ct: (request.selectedTechnologyId.length > 0) ? request.selectedTechnologyId : undefined,
      },
      relativeTo: this.route
    }
    this.home.query = request.fullTextSearch
  }
}
