export enum FilterType {
  Select
}

export interface FilterValue {
  value: unknown;
  displayValue: string;
  displayValueSuffix?: string;
}

export interface Filter {
  name: string;
  nameSuffix: string;
  type: FilterType;
  parentId?: string;
  selectedValue: FilterValue | undefined;
  options: FilterValue[];
}
