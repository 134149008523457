import { Component } from '@angular/core';
import { EquipmentInfo } from "../model/equipment-api";
import { ActivatedRoute } from "@angular/router";
import { EquipmentService } from "../model/equipment.service";
import { AlarmLevelEntry } from "../../site/model/site-api";
import { EquipmentDetailsAlarmDialogComponent } from "../equipment-details-alarm-dialog/equipment-details-alarm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { AlarmService } from "../../alarm/model/alarm.service";
import { ActiveAlarmInfoEntry } from "../../alarm/model/alarm-api";
import { interval, Subscription } from "rxjs";
import { LicenseService } from "../../accounting/model/license.service";
import { LicenseInfo } from "../../accounting/model/license-api";
import { ChargeEntry } from "../../accounting/model/charge-api";
import moment from "moment";
import { formatDate } from "@angular/common";
import { PropertyService } from "../../property/model/property.service";
import { PropertyDouble } from "../../property/model/property-api";
import { AccountService } from "../../accounting/model/account.service";

@Component({
  selector: 'app-equipment-details-status',
  templateUrl: './equipment-details-status.component.html',
  styleUrls: ['./equipment-details-status.component.scss']
})
export class EquipmentDetailsStatusComponent {

  equipment: EquipmentInfo | undefined
  properties: Map<string, PropertyDouble> = new Map
  activeAlarmInfo: ActiveAlarmInfoEntry[] = []
  license: LicenseInfo | undefined
  charge: ChargeEntry | undefined
  lastValueUpdate: string | undefined

  private reloadSubscription: Subscription | null = null
  reloadingEquipment: boolean = false
  reloadingProperties: boolean = false
  reloadingAlarms: boolean = false

  constructor(
    public service: EquipmentService,
    private propertyService: PropertyService,
    private alarmService: AlarmService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private licenseService: LicenseService,
    private accountService: AccountService
  ) {
    this.activatedRoute.paramMap.subscribe(paramsMap => {
      const equipmentId = Number(paramsMap.get('id'))
      if (!equipmentId) return
      this.service.getInfo(equipmentId).subscribe(equipment => {
        this.reloadingEquipment = true
        this.equipment = equipment
        this.reloadData()
        this.reloadingEquipment = false
      })
      this.getLicensingInfo(equipmentId);
    });
  }

  private getLicensingInfo(equipmentId: number) {
    this.licenseService.findByEquipment(equipmentId).subscribe(info => {
      if (!info) return
      this.license = info
      this.accountService.getChargeEntries(info.license.accountId, 0, 100).subscribe(charge => {
        if (!charge) return
        charge.content.forEach(entry => {
          if (entry.licenseId == info.license.id) {
            this.charge = entry
          }
        })
      })
    })
  }

  protected readonly Math = Math;

  ngOnInit() {
    if (!this.reloadSubscription) {
      this.reloadSubscription = interval(30000).subscribe(_ => this.reloadData())
    }
  }

  private reloadData() {
    this.reloadEquipment()
    this.reloadProperties()
    this.reloadAlarms()
  }

  private reloadEquipment() {
    if (!this.equipment) return
    if (this.reloadingEquipment) return
    this.reloadingEquipment = true
    this.service.getInfo(this.equipment.equipment.id).subscribe(info => this.handleEquipmentData(info))
  }

  private handleEquipmentData(info: EquipmentInfo) {
    this.equipment = info
    this.reloadingEquipment = false
  }

  private reloadProperties() {
    if (!this.equipment) return
    if (this.reloadingProperties) return
    this.reloadingProperties = true
    this.propertyService.getLastThingDoubleProperties(this.equipment.equipment.thingId, [])
      .subscribe(it => this.handlePropertyData(it))
  }

  private handlePropertyData(data: Map<string, PropertyDouble>) {
    let values = Object.entries(data)
    this.properties = new Map(values)

    let timestamps = values.map(([key, value]) => moment(value.timestamp + 'Z'))
    this.lastValueUpdate = timestamps.length <= 0 ? undefined : moment.max(timestamps).toISOString()
    this.reloadingProperties = false
  }


  private reloadAlarms() {
    if (!this.equipment) return
    if (this.reloadingAlarms) return
    this.reloadingAlarms = true
    this.alarmService.getActiveAlarmInfo(this.equipment.equipment.id).subscribe(d => this.handleAlarmData(d))
  }

  private handleAlarmData(data: ActiveAlarmInfoEntry[]) {
    this.activeAlarmInfo = data
    this.reloadingAlarms = false
  }

  round(data: number | undefined) {
    if (!data) return
    return Math.round(data)
  }

  getAlarmCount(alarmState: AlarmLevelEntry[]) {
    if (!alarmState) return

    let alarms = alarmState.filter(alarm => alarm.level !== 'INFO')
    return alarms.reduce((sum, alarm) => sum + alarm.amount, 0)
  }


  showActiveAlarmDialog() {
    if (!this.equipment) return
    const dialogRef = this.dialog.open(EquipmentDetailsAlarmDialogComponent, {
      data: this.equipment.equipment
    })

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  isActivated(): boolean {
    return this.equipment?.equipment.activated ?? false
  }

  ngOnDestroy() {
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe()
      this.reloadSubscription = null
    }
  }

  getDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }

  protected readonly formatDate = formatDate;
}
