import {Component, output, signal} from '@angular/core';
import {HomeService} from '../model/home.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
})
export class MainMenuComponent {
  collapsed = signal(false);
  onCollapsed = output<boolean>();

  constructor(public service: HomeService) {
  }

  toggleCollapsed() {
    this.collapsed.set(!this.collapsed());
    this.onCollapsed.emit(this.collapsed());
  }
}
