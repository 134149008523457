<mat-expansion-panel [expanded]="expanded()">
  <mat-expansion-panel-header class="mb-4">
    <mat-panel-title class="font-medium text-2xl"> {{ filterListTitle() }}&nbsp;<span
      class="opacity-60">{{ filterListTitleSuffix() }}</span>
    </mat-panel-title>
    <div class="flex items-center mr-6 opacity-60">
      <button (click)="onFiltersResetClick($event)" mat-button>Reset Filters</button>
      <mat-icon>refresh</mat-icon>
    </div>
  </mat-expansion-panel-header>
  @if (filterGroups().length) {
    <div class="group-container">

      @for (rootGroups of filterGroups(); track rootGroups) {
        <div class="filter-group">
          @for (group of rootGroups; track group.groupId) {

            <app-filter-group [filters]="group.filters"
                              (filterSelected)="filterSelected.emit($event)"
                              (filterCleared)="filterCleared.emit($event)"></app-filter-group>
          }
        </div>
      }

    </div>
  }
</mat-expansion-panel>
