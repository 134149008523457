import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackComponent } from './feedback/feedback.component';
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../../material/material.module";
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { FeedbackButtonComponent } from './feedback-button/feedback-button.component';
import { FeedbackEntryDialogComponent } from './feedback-entry-dialog/feedback-entry-dialog.component';


@NgModule({
  declarations: [
    FeedbackComponent,
    FeedbackDialogComponent,
    FeedbackButtonComponent,
    FeedbackEntryDialogComponent
  ],
  exports: [
    FeedbackComponent,
    FeedbackButtonComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule
  ]
})
export class FeedbackModule {
}
