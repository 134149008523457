import { Injectable, NgZone } from '@angular/core';
import { BaseService } from "../../../utils/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { LoggingService } from "../../../utils/logging/logging.service";
import { Observable, Subject } from "rxjs";
import {
  Wishlist, WishlistAccessEntry, WishlistAccessPatchRequest,
  WishlistChangeRequest,
  WishlistEntry,
  WishlistEntryChangeRequest,
  WishlistInfo
} from "./wishlist-api";
import { Page } from "../../../utils/page";

@Injectable({
  providedIn: 'root'
})
export class WishlistService extends BaseService {

  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone
  ) {
    super(http, 'api/portal/wishlist', logging, zone)
    this.retryCount = 0
  }

  getWishlist(id: number): Observable<Wishlist> {
    return this.get('' + id)
  }

  getAllWishlists(page: number, size: number): Observable<Page<Wishlist>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.get('', params)
  }

  createWishlist(request: WishlistChangeRequest): Observable<Wishlist> {
    return this.post('', request)
  }

  updateWishlist(id: number, request: WishlistChangeRequest): Observable<Wishlist> {
    return this.put('' + id, request)
  }

  deleteWishlist(id: number): Observable<Wishlist> {
    return this.delete('' + id)
  }

  getWishlistEntries(id: number, page: number, size: number): Observable<Page<WishlistEntry>> {
    let params = new HttpParams()
      .append('page', page)
      .append('size', size)
    return this.get('' + id + '/entry', params)
  }

  addWishlistEntry(id: number, request: WishlistEntryChangeRequest): Observable<Page<WishlistEntry>> {
    return this.post('' + id + '/entry', request)
  }

  updateWishlistEntry(id: number, entryId: number, request: WishlistEntryChangeRequest): Observable<Page<WishlistEntry>> {
    return this.put('' + id + '/entry/' + entryId, request)
  }

  removeWishlistEntry(id: number, entryId: number): Observable<Page<WishlistEntry>> {
    return this.delete('' + id + '/entry/' + entryId)
  }

  removeAllWishlistEntries(id: number): Observable<Page<WishlistEntry>> {
    return this.delete('' + id + '/entry')
  }

  getWishlistInfo(id: number): Observable<WishlistInfo> {
    return this.get('info/' + id)
  }

  checkWishlist(references: string[]): Observable<WishlistEntry[]> {
    return this.put('entry', references)
  }

  getAccess(wishlistId: number, page: number, size: number): Observable<Page<WishlistAccessEntry>> {
    return this.getPaged(`${wishlistId}/access`, page, size)
  }

  setWriteable(wishlistId: number, value: WishlistAccessPatchRequest) {
    return this.patch(`${wishlistId}/access/writeable`, value)
  }

  setReadable(wishlistId: number, value: WishlistAccessPatchRequest) {
    return this.patch(`${wishlistId}/access/readable`, value)
  }
}
