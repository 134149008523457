import { Injectable } from '@angular/core';
import { EquipmentRunningTimeEntry, EquipmentRunningTimeReport } from "../../model/operational-report-activity-api";
import { Alarm } from "../../../../alarm/model/alarm-api";
import { SeriesOption } from "echarts";
import { AlarmType } from "../../../../site/model/site-api";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { OperationalReportService } from "../../model/operational-report.service";
import { SystemTimeService } from "../../../../system/system-time/system-time.service";

@Injectable({
  providedIn: 'root'
})
export class ActivityRunningService {

  eventStatusCategory = 1
  eventOnlineCategory = 2

  constructor(
    private service: OperationalReportService,
    private systemTime: SystemTimeService,
    private translate: TranslateService,
  ) { }


  process(data: EquipmentRunningTimeReport) {
    if (!data) return []

    let statusEvents = data.entries.map(entry => {
      if (entry.type != this.eventStatusCategory) return
      return this.addEntry(entry)
    }).flatMap(e => e ? [e] : [])

    let offlineEvents = data.entries.map(entry => {
      if (entry.type != this.eventOnlineCategory) return
      return this.addEntry(entry)
    }).flatMap(e => e ? [e] : [])

    let runningSeries = this.createMarkSeries(
      'Running',
      .4,
      '#b9e5b8',
      statusEvents
    )

    let onlineSeries = this.createMarkSeries(
      'Offline',
      .5,
      '#656565',
      offlineEvents
    )
    let alarms = this.createAlarmSeries(data.alarms)

    return [runningSeries, onlineSeries, alarms]
  }

  private addEntry(entry: EquipmentRunningTimeEntry): EventMarker {
    const start = this.systemTime.formatTimestamp(entry.start)
    const stop = this.systemTime.formatTimestamp(entry.stop)
    return { begin: start, end: stop }
  }

  private createAlarmSeries(alarm: Alarm[]): SeriesOption {
    return {
      type: 'line',
      lineStyle: {
        width: 1
      },
      emphasis: {
        focus: 'series'
      },
      markArea: {
        tooltip: {
          show: false
        },
        data: alarm.map(a => {
          const timestamp = this.systemTime.formatTimestamp(a.timestamp)
          return [{
            text: 'Alarm',
            xAxis: moment(timestamp).subtract(15, 'm').toISOString(),
            yAxis: 30,
            itemStyle: {
              color: this.setAlarmDataColor(a)
            }
          }, {
            xAxis: moment(timestamp).add(15, 'm').toISOString(),
            yAxis: 50
          }]
        })
      },
    }
  }

  private setAlarmDataColor(alarm: Alarm): string {
    switch (alarm.metadata.level) {
      case AlarmType.FAULT:
        return 'red'
      case AlarmType.CRITICAL:
        return 'orange'
      case AlarmType.WARNING:
        return 'yellow'
      default:
        return 'red'
    }
  }

  private createMarkSeries(title: string, opacity: number, color: string, marker: EventMarker[]): SeriesOption {
    return {
      name: title === 'Running' ? this.translate.instant('ioTStatusText_Running') : this.translate.instant('ioTStatusText_Offline'),
      type: 'line',
      lineStyle: {
        width: 1
      },
      emphasis: {
        focus: 'series'
      },
      markArea: {
        emphasis: {
          label: {
            show: true,
            color: '#000',
            backgroundColor: 'white',
            borderColor: '#bfbfbf',
            borderWidth: 0.8,
            padding: 8,
            opacity: 1,
            distance: -5,
            formatter: (params) => {
              // @ts-ignore
              const t = params.data.text
              const text = t === 'Running' ? this.translate.instant('ioTStatusText_Running') : this.translate.instant('ioTStatusText_Offline')
              // @ts-ignore
              const start = new Date(params.data.coord[0][0]);
              // @ts-ignore
              const end = new Date(params.data.coord[1][0]);
              const duration = end.getTime() - start.getTime();
              return `${text}: ${this.formatDuration(duration)}`;
            },
          },
        },
        itemStyle: {
          opacity: opacity,
          color: color,
        },
        data: marker.map(m => [{text: title, xAxis: m.begin}, {xAxis: m.end, yAxis: 40}])
      },
    }
  }

  private formatDuration(value: number) {
    const time = moment.duration(value).asSeconds() / 60
    const hours = Math.floor(time / 60)
    const minutes = Math.ceil(time % 60)
    return `${hours} ${this.translate.instant('hours')} ${minutes} ${this.translate.instant('minutes')}`
  }
}


interface EventMarker {
  begin: string,
  end: string
}
