<mat-accordion>
  @for (c of service.result.items; track $index) {
    @if (c.possibleValues.length > 0) {
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>{{ c.name }}</mat-panel-title>

          <mat-panel-description class="justify-between">
            @if (c.selectedValue) {
              <div>{{ c.selectedValue.name }}</div>
            }

            @if (c.selectionType === ConfigSelectionType.USER) {
              <mat-icon color="accent ml-auto">check_box</mat-icon>
            } @else if (c.selectionType === ConfigSelectionType.ONLY_REMAINING_OPTION) {
              <mat-icon color="primary ml-auto">check_box</mat-icon>
            } @else if (c.selectionType === ConfigSelectionType.DEFAULT) {
              <mat-icon style="color: #b36d23">check_box</mat-icon>
            }
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="flex gap-2 flex-wrap">
          @if (c.selectedValue) {
            <button mat-stroked-button color="warn"
                    [disabled]="c.selectionType === ConfigSelectionType.ONLY_REMAINING_OPTION"
                    (click)="service.unselectConfigItem(c)">
              <mat-icon>close</mat-icon>
              {{ c.selectedValue.name }}
            </button>
          } @else {
            @if (c.defaultValue) {
              <button mat-stroked-button style="color: #b36d23" (click)="service.selectConfigItem(c, c.defaultValue)">{{ c.defaultValue.name }}</button>
            }
            @for (o of c.possibleValues; track o) {
              @if (o.key != c.defaultValue?.key) {
                <button mat-stroked-button color="primary" (click)="service.selectConfigItem(c, o)">{{ o.name }}</button>
              }
            }
          }
        </div>
      </mat-expansion-panel>
    }
  }
</mat-accordion>
