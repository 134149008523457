import { Injectable, NgZone } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BaseService } from "../../../utils/base-service";
import { LoggingService } from "../../../utils/logging/logging.service";
import { ProductComparisonRequest, ProductComparisonResponse, ProductConfigSearchRequest, ProductConfigSearchResult } from "./product-config-api";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ProductConfigService extends BaseService {
  constructor(
    http: HttpClient,
    logging: LoggingService,
    zone: NgZone,
    private translateService: TranslateService,
  ) {
    super(http, 'api/logic/pid', logging, zone)
    this.retryCount = 0
  }

  searchDefault(productId: string): Observable<ProductConfigSearchResult> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
      .append('productId', productId)
    return this.post('search/default', {}, params)
  }

  search(request: ProductConfigSearchRequest): Observable<ProductConfigSearchResult> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)
    return this.post('search', request, params)
  }

  compare(request: ProductComparisonRequest): Observable<ProductComparisonResponse> {
    let params = new HttpParams()
      .append('lang', this.translateService.currentLang)

    return this.post('compare', request, params)
  }
}
