import { Component, Input } from '@angular/core';
import { EventState } from "../../site/model/state-api";
import * as moment from "moment";

@Component({
  selector: 'app-time-in-fault',
  templateUrl: './time-in-fault.component.html',
  styleUrls: ['./time-in-fault.component.scss']
})
export class TimeInFaultComponent {

  @Input('state')
  set state(state: EventState) {
    this.eventState = state
    this.updateText()
  }

  @Input()
  set showUndefined(value: boolean) {
    this._showUndefined = value
    this.updateText()
  }

  private _showUndefined: boolean = true

  eventState: EventState | undefined
  text: string = ''

  private updateText() {
    let state = this.eventState;
    if (!state || !state.online) {
      this.text = this.getUndefined()
    } else {
      const timestamp = moment(state.timestamp + 'Z').utc()
      const current = moment().utc()
      const duration = moment.duration(current.diff(timestamp))
      this.text = duration.asMilliseconds() < 0 ? this.getUndefined() : duration.humanize()
    }
  }

  private getUndefined() {
    return (this.showUndefined) ? 'N/A' : '';
  }
}
