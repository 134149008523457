import { Component } from '@angular/core';
import { ProductConfigSearchService } from "../model/product-config-info.service";
import { AuthService } from "../../../auth/auth.service";
import { PriceRequestEntry, PriceResponse } from "../../../commerce/model/commerce-api";
import { CommerceCartService } from "../../../commerce/model/commerce-cart.service";
import { ProductConfigSearchResult } from "../model/product-config-api";
import { WishlistService } from "../../wishlist/model/wishlist.service";
import { MatDialog } from "@angular/material/dialog";
import { WishlistEntry } from "../../wishlist/model/wishlist-api";
import { COMMERCE_ADMIN, COMMERCE_READ } from "../../../auth/roles";

@Component({
  selector: 'app-product-config-search-result',
  templateUrl: './product-config-search-result.component.html',
  styleUrl: './product-config-search-result.component.scss'
})
export class ProductConfigSearchResultComponent {
  displayedColumns = ['id']
  prices: Record<string, number> = {}
  wishlistActive: WishlistEntry[] = []

  constructor(
    public service: ProductConfigSearchService,
    private commerceService: CommerceCartService,
    private authService: AuthService,
    private wishlistService: WishlistService,
    private dialog: MatDialog,
  ) {
    service.searchObservable.subscribe(s => {
      this.updatePrice(s)
      this.updateWishlistActive()
    })
  }

  hasCommerceReadPermissions(): boolean {
    return this.authService.hasRole(COMMERCE_READ) || this.authService.hasRole(COMMERCE_ADMIN)
  }

  private updatePrice(s: ProductConfigSearchResult) {
    if (!this.hasCommerceReadPermissions()) return

    let pids = s.suggestedEntries.slice()
    if (s.entries.length <= 10) {
      let missingPids = s.entries
        .filter(p => !pids.find(e => e.id === p.id))
      pids.push(...missingPids)
    }

    let entries = pids.map(p => new PriceRequestEntry(p.id, 1))
    this.commerceService.getPrices(entries).subscribe({
      next: value => this.handlePriceInfo(value)
    })
  }

  private handlePriceInfo(response: PriceResponse) {
    response.entries.forEach(p => {
      this.prices[p.code] = p.totalPrice
    })
  }

  private updateWishlistActive() {
    const references = this.service.result.entries.map(entry => entry.id)
    if (references.length) this.wishlistService.checkWishlist(references).subscribe(data => {
      this.wishlistActive = data
    })
  }
}
