import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { PageEvent } from "@angular/material/paginator";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SparePartSearchRequest, SparePartSearchResponse, SparePartSearchResultEntry } from "../model/spare-part-api";
import { SparePartService } from "../model/spare-part.service";
import { SparePartsBoardContentDetailsComponent } from "../spare-parts-board-content-details/spare-parts-board-content-details.component";

let DEFAULT_PAGE_SIZE = 15;

@Component({
  selector: 'app-spare-parts-board-content',
  templateUrl: './spare-parts-board-content.component.html',
  styleUrl: './spare-parts-board-content.component.scss'
})
export class SparePartsBoardContentComponent {

  @Output() reset = new EventEmitter<boolean>()
  @Output() requestChanged = new EventEmitter<SparePartSearchRequest>()

  searching: boolean = false
  pageSize: number = DEFAULT_PAGE_SIZE
  pageIndex: number = 0
  totalSize: number = -1

  response: SparePartSearchResponse | null = null
  request: SparePartSearchRequest = new SparePartSearchRequest('', this.translate.currentLang)
  selected: SparePartSearchResultEntry | undefined

  private subscriptions: Subscription[] = []

  @ViewChild(SparePartsBoardContentDetailsComponent) details: SparePartsBoardContentDetailsComponent | undefined

  constructor(
    private service: SparePartService,
    private translate: TranslateService,
  ) {

  }

  ngOnInit() {
    let s1 = this.translate.onLangChange.subscribe(evt => {
      this.request.lang = evt.lang
      this.handleRequestChanged()
    })
    this.subscriptions = [s1]
  }

  ngAfterContentInit() {
    this.load()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
    this.subscriptions = []
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex
    this.pageSize = event.pageSize
    this.load()
  }

  resetFilter() {
    this.request = new SparePartSearchRequest('', this.translate.currentLang)
    this.reset.emit()
    this.handleRequestChanged()
  }

  search(query: string) {
    this.request.fullTextSearch = query
    this.handleRequestChanged()
  }

  private load(overwrite: boolean = false) {
    if (this.searching && !overwrite) return
    this.searching = true

    this.service.search(this.request, this.pageIndex, this.pageSize)
      .subscribe(d => this.handleData(d.body))
  }


  private handleData(response: SparePartSearchResponse | null): boolean {
    if (!response) return false
    this.response = response
    this.pageIndex = response.entries.pageable.number
    this.totalSize = response.entries.totalSize
    if (response.entries.content.length > 0)
      this.handleSelectionChanged(response.entries.content[0])
    else
      this.handleSelectionChanged(undefined)
    this.searching = false
    return true
  }

  private handleRequestChanged() {
    this.pageIndex = 0
    this.requestChanged.emit(this.request)
    this.load()
  }

  updateRequest(query: string) {
    if (this.request.fullTextSearch !== query) {
      this.request.fullTextSearch = query
      this.load()
    }
  }

  handleSelectionChanged(event: SparePartSearchResultEntry | undefined) {
    this.selected = event
    if (this.details) this.details.handleSelectionChanged(this.selected)
  }
}
