import {Component, input, output} from '@angular/core';
import {Filter, FilterValue} from "../filter.const";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './filter.component.html',
  styleUrl: './filter.component.scss'
})
export class FilterComponent {
  filter = input.required<Filter>();
  filterSelected = output<unknown>();
  filterCleared = output<unknown>();
  control = new FormControl();

  constructor() {
    toObservable(this.filter).pipe(takeUntilDestroyed()).subscribe((filter) => {
      if (filter.selectedValue) {
        this.control.setValue(filter.selectedValue);
      }
    });
  }

  clear(event: MouseEvent) {
    event.stopPropagation();
    
    this.filterCleared.emit(this.control.value);
    this.control.reset();
  }

  displayFn(option: FilterValue): string {
    return option?.displayValue;
  }

}
