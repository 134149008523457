import { RelatedCategoryIdEntry } from "../../document/model/document-api";
import { Page } from "../../../utils/page";
import { CategorySelectRequest } from "../../category/category-select/category-select.component";

export interface Series {
  id: string,
  mediaTitle: string,
  notFor: string,
  onlyFor: string,
  pictogram: string,
  picture: string,
  title: string,
  documents: any
}

export interface SeriesInfo {
  id: string,
  title: string,
  description: string,
  notFor: string[],
  onlyFor: string[],
  picture: string,
  mediaTitle: string,
  pictogram: string[],
  documents: DocumentReferences
}

export interface DocumentReferences {
  documents: string[]
}

export class SeriesSearchRequest implements CategorySelectRequest {
  constructor(
    public categoryIds: string[],
    public fullTextSearch: string,
    public lang: string
  ) {
  }

  selectedApplicationId: string = '';
  selectedTechnologyId: string = '';
}

export interface SeriesSearchResponse {
  entries: Page<SeriesSearchResultEntry>,
  categories: RelatedCategoryIdEntry[]
}

export interface SeriesSearchResultEntry {
  id: string,
  title: string,
  description: string,

  picture: string,
  mediaTitle: string,
  pictogram: string[],

  documents: string[]
}
