<ng-container *ngIf="service.selectedSparePart">
  <mat-card style="overflow-y: auto;" [style.max-height]="imageMaxHeight" [style.min-height]="imageMaxHeight">
    <img src="https://oiservicesprod.blob.core.windows.net/images/large/{{service.selectedSparePart.id}}.jpg"
         appDefaultImage
         mat-card-image style="max-width: 100%;" alt="Spare Part Picture">
    <mat-card-content>
      <table mat-table [dataSource]="content" *ngIf="content.length > 0">
        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row;"> {{ row.text | translate }}</td>
        </ng-container>
        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row"> {{ row.content }}</td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="dense-1"></tr>
      </table>

      <mat-divider></mat-divider>
      <div class="flex flex-row gap-3 items-center">
        <app-add-to-cart-button [code]="this.service.selectedSparePart?.id ?? ''" mode="button"></app-add-to-cart-button>
        <button mat-mini-fab (click)="addToWishList()"
                matTooltip="{{'wishlist.dialog.add' | translate}}"
                [style.color]="isWishlistActive() ? '#50c422' : 'black'">
          <mat-icon>star</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
