import { Component } from '@angular/core';
import { AlarmInfo } from "../../alarm/model/alarm-api";
import { EquipmentInfo } from "../model/equipment-api";
import { FormControl, FormGroup } from "@angular/forms";
import { AlarmService } from "../../alarm/model/alarm.service";
import { ActivatedRoute } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
import { Page } from "../../utils/page";
import { EquipmentService } from "../model/equipment.service";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import DurationConstructor = moment.unitOfTime.DurationConstructor;

@Component({
  selector: 'app-equipment-details-alarms',
  templateUrl: './equipment-details-alarms.component.html',
  styleUrls: ['./equipment-details-alarms.component.scss']
})
export class EquipmentDetailsAlarmsComponent {

  equipment: EquipmentInfo | undefined
  reloading: boolean = false
  data: Page<AlarmInfo> | undefined
  pageSize = 10
  pageIndex: number = 0

  selectedTime: string = '1'
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor(
    public service: EquipmentService,
    private alarmService: AlarmService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(paramsMap => {
      const equipmentId = Number(paramsMap.get('id'))
      if (!equipmentId) return
      this.service.getInfo(equipmentId).subscribe(equipment => {
        this.equipment = equipment
        if (this.equipment.equipment.activated) this.loadAlarms(this.pageIndex, this.pageSize)
      })
    })
    this.range.valueChanges.subscribe(dates => {
        if (dates.start && dates.end) this.filterAlarmsOnRangeChange()
      }
    )
  }

  filterAlarmsOnPreset() {
    if (!this.selectedTime) return
    this.range.reset()
    this.loadAlarms(0, this.pageSize)
  }

  filterAlarmsOnRangeChange() {
    this.selectedTime = ''
    this.loadAlarms(0, this.pageSize)
  }


  handlePaginatorEvent(event: PageEvent) {
    this.loadAlarms(event.pageIndex, event.pageSize)
  }


  private loadAlarms(page: number, size: number) {
    if (!this.equipment) return
    if (this.reloading) return

    this.reloading = true

    let equipmentId = this.equipment.equipment.id

    const now = moment()
    let start = moment(now).subtract(1, this.getTimestamp(this.selectedTime)).toISOString()
    let end = now.toISOString()


    let startDate = this.range.get('start')?.value
    let endDate = this.range.get('end')?.value
    if (startDate && endDate) {
      start = moment(startDate).toISOString()
      end = moment(endDate).toISOString()
    } else if (this.selectedTime) {
      const amount = parseInt(this.selectedTime, 10)
      start = moment(now).subtract(amount, this.getTimestamp(this.selectedTime)).toISOString()
      end = now.toISOString()
    }

    this.alarmService.search(equipmentId, start, end, this.translate.currentLang, page, size).subscribe(d => this.handleData(d))
  }

  private getTimestamp(time: string): DurationConstructor | undefined {
    switch (time) {
      case '28':
      case '7':
      case '1':
        return 'days'
      default:
        return undefined
    }
  }

  private handleData(data: Page<AlarmInfo>) {
    this.data = data
    this.pageIndex = data.pageable.number
    this.pageSize = data.pageable.size
    this.reloading = false
  }

}
