import { Component } from '@angular/core';
import { FormControl } from "@angular/forms";
import { interval, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { OperationalReportService } from "../model/operational-report.service";

@Component({
  selector: 'app-operational-report-header',
  templateUrl: './operational-report-header.component.html',
  styleUrl: './operational-report-header.component.scss'
})
export class OperationalReportHeaderComponent {

  refreshControl = new FormControl<number>(0);
  refreshSelection: number[] = [0, 5, 10]
  systemTime: boolean = false
  private refreshSubscription: Subscription | undefined

  constructor(
    public service: OperationalReportService,
    private dialog: MatDialog
  ) {
  }

  ngOnDestroy() {
    this.refreshSubscription?.unsubscribe()
    this.refreshSubscription = undefined
  }

  refresh() {
    this.service.refresh()
  }

  changeTimer(event: MatSelectChange) {
    let value = event.value ?? 0
    let period = value * 60 * 1000

    this.refreshSubscription?.unsubscribe()
    this.refreshSubscription = undefined
    if (value > 0) {
      this.refreshSubscription = interval(period).subscribe(_ => this.refresh())
    }
  }

  toggleSystemTime() {
    this.systemTime = !this.systemTime
    this.service.switchSystemTime(this.systemTime)
  }
}
