export interface PropertyDouble {
  timestamp: string,
  value: number,
  metadata: PropertyMetadata
}

export interface PropertyText {
  timestamp: string,
  value: string,
  metadata: PropertyMetadata
}

export interface PropertyMetadata {
  name: string,
  source: ThingValueSource
}

export interface ThingValueSource {
  thingId: number,
  thingTypeId: number,
  origin: string,
  text: string,
  inputType: string
}

export interface EnvelopePoint {
  x: number,
  y: number
}

export interface EnvelopePropertyEntry {
  x: PropertyDouble,
  y: PropertyDouble
}

export enum PropertyName {
  "BDN.Capacity" = 0,
  "BDN.CondTemp" = 1,
  "BDN.EvapTemp" = 2,
  "BDN.OilDiscGasTemp" = 3
}

export enum SiUnit {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT',
  BAR = 'BAR',
  PSI = 'PSI'
}

export interface EnvelopeInfo {
  type: SiUnit,
  points: EnvelopePoint[]
}

export class PropertySearchRequest {
  constructor(
    public from: string,
    public to: string,
    public names: string[]
  ) {
  }
}
