export interface ProductConfigSearchResult {
  request: ProductConfigSearchRequest
  items: ConfigItemEntry[]
  entries: ProductConfigIdInfo[]
  suggestedEntries: ProductConfigIdInfo[]
}

export interface ConfigItemEntry {
  key: string
  defaultName: string
  name: string
  selectionType: ConfigSelectionType
  selectedValue: CsticValueInfo | null
  defaultValue: CsticValueInfo | null
  possibleValues: CsticValueInfo[]
}

export interface ProductConfigIdInfo {
  id: string;
  matNrKMat: string;
  type: string;
  plant: string;
  cstics: CsticInfo[];
}

export interface CsticInfo {
  key: string
  defaultName: string
  name: string
  value: CsticValueInfo
}

export interface CsticValueInfo {
  key: string
  defaultName: string
  name: string
}

export class ProductConfigSearchRequest {
  constructor(
    public productId: string,
    public entries: ConfigItemSelectEntry[]
  ) {
  }
}


export class ConfigItemSelectEntry {
  constructor(
    public key: string,
    public value: string,
    public selectionType: ConfigSelectionType
  ) {
  }
}

export enum ConfigSelectionType {
  USER = 'USER',
  DEFAULT = 'DEFAULT',
  ONLY_REMAINING_OPTION = 'ONLY_REMAINING_OPTION',
  NO_SELECTION = 'NO_SELECTION'
}


export class ProductComparisonRequest {
  constructor(
    public configIds: string[]
  ) {
  }
}

export interface ProductComparisonResponse {
  header: ProductConfigIdInfo[]
  rows: ConfigCompareRow[]
}

export interface ProductConfigIdInfo {
  id: string;
  matNrKMat: string;
  type: string;
}

export interface ConfigCompareRow {
  defaultName: string
  key: string
  name: string
  similarValues: boolean
  values: ConfigItemValueEntry[]
}

export interface ConfigItemValueEntry {
  key: string
  defaultName: string
  name: string
}
