import { Component } from '@angular/core';
import { HomeService } from "../model/home.service";

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.scss'
})
export class MainHeaderComponent {
  constructor(public service: HomeService) {
  }
}
