<div class="container-fluid flex flex-row flex-wrap gap-3 pt-3">
  <mat-card class="equ-card" *ngIf="equipment && isActivated()">
    <mat-card-header class="card-header">
      <mat-card-title>
        {{ 'opsStatus' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col gap-2">
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "compressor.title" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">
              <app-iot-status [eventState]="equipment.eventState"></app-iot-status>
            </p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "lastValUpdate" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ lastValueUpdate | date: 'medium' }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "actualAlarms" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <div class="flex flex-row content-end items-center gap-2">
              <ng-container *ngFor="let info of activeAlarmInfo">
                <mat-icon class="fault" *ngIf="info.level == 'FAULT'">warning</mat-icon>
                <mat-icon class="critical" *ngIf="info.level == 'CRITICAL'">warning</mat-icon>
                <mat-icon class="warn" *ngIf="info.level == 'WARNING'">warning</mat-icon>
              </ng-container>
              <button mat-button *ngIf="activeAlarmInfo.length > 0" (click)="showActiveAlarmDialog()"
                      color="warn">{{ 'multipleAlarms' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>
  <mat-card class="equ-card" *ngIf="equipment && isActivated()">
    <mat-card-header class="card-header">
      <mat-card-title>{{ 'mainIndicators' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col gap-2">
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "evapTemp" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ properties.get("BDN.EvapTemp")?.value }} {{ 'tempC' | translate }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "condTemp" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ properties.get("BDN.CondTemp")?.value }} {{ 'tempC' | translate }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "dischargeGasTemperature" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ properties.get("BDN.OilDiscGasTemp")?.value }} {{ 'tempC' | translate }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "capacity" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ properties.get("BDN.Capacity")?.value }}%</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "capacitySet" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ properties.get("BDN.CapacitySet")?.value }}%</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="equ-card" *ngIf="equipment">
    <mat-card-header class="card-header">
      <mat-card-title>{{ 'applicationEnvelope' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-equipment-envelope [data]="equipment"></app-equipment-envelope>
    </mat-card-content>
  </mat-card>

  <mat-card class="equ-card" *ngIf="equipment && isActivated()">
    <mat-card-header class="card-header">
      <mat-card-title>{{ 'infoCounters' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col gap-2">
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "compOpsTime" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ round(properties.get("BDN.ComprRunTime")?.value) }} h</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "opsTimeProtectionModule" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ round(properties.get("BDN.ModuleOperateTime")?.value) }} h</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "compStarts" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ properties.get("BDN.ComprStartCounts")?.value }}</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="equ-card" *ngIf="equipment">
    <mat-card-header class="card-header">
      <mat-card-title>{{ 'registrationStatus' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col gap-2">
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "registered" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ equipment.equipment.registeredOn + 'Z' | date:'medium' }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "activated" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ equipment.equipment.activatedOn + 'Z' | date:'medium' }}</p>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="flex flex-row gap-2">
          <div class="basis-1/2">
            <p class="float-start">{{ "servicePackage" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">{{ license?.model?.title || 'B-EASY' }}</p>
          </div>
        </div>
        <div class="flex flex-row gap-2" *ngIf="charge">
          <div class="basis-1/2">
            <p class="float-start">{{ "validityPeriod" | translate }}</p>
          </div>
          <div class="basis-1/2">
            <p class="float-end">
              {{ getDate(charge.validityPeriodStart) }}
              .. {{ getDate(charge.validityPeriodEnd) }}</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

