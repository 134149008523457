import {Injectable} from '@angular/core';
import {FilterType} from "./filter.const";
import {FilterGroup} from "./filter-group/filter-group.component";
import {CategoryGroup, ExtendedCategory} from "../../portal/product/model/product-facade.service";
import {ProductSearchResponse} from "../../portal/product/model/product-api";

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  toFilterGroups(rootGroups: {
    application: CategoryGroup[],
    technology: CategoryGroup[]
  }, response: ProductSearchResponse | null): FilterGroup[][] {
    if (!rootGroups) return [] as FilterGroup[][];
    const selectedApplicationId = rootGroups.application[rootGroups.application.length - 1].id;
    const selectedTechnologyId = rootGroups.technology[rootGroups.technology.length - 1].id;
    return Object.values(rootGroups).map((root) => {
      return root.map((group: CategoryGroup) => {
        const filter = {
          name: `Select ${group?.name ?? ''} Type`,
          nameSuffix: response?.entries.totalSize.toString(),
          type: FilterType.Select,
          selectedValue: group.selectedValue,
          options: group?.categories.filter(c => !!c.amount).map((categoryEntry: ExtendedCategory) => ({
            value: {
              groupName: group.name,
              parentGroupName: root[0].name,
              categoryId: categoryEntry.category.id,
              selectedApplicationId,
              selectedTechnologyId,
              selectedIds: [selectedApplicationId, selectedTechnologyId].filter(item => item?.length ?? 0 > 0)
            },
            displayValue: categoryEntry.category.title,
            displayValueSuffix: categoryEntry.amount
          }))
        };

        return {
          groupName: group?.name,
          groupId: group?.id,
          filters: [filter],
        } as FilterGroup;

      });
    });
  }
}
