import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "./material/material.module";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { AuthModule } from "./auth/auth.module";
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from "@angular/common";
import { ErrorModule } from "./error/error.module";
import { MainMenuModule } from "./component/main-menu/main-menu.module";
import { NgxEchartsModule } from "ngx-echarts";
import { CommerceModule } from "./commerce/commerce.module";
import { NgxGoogleAnalyticsModule, provideGoogleAnalytics, provideGoogleAnalyticsRouter } from "@hakimio/ngx-google-analytics";
import { environment } from "../environments/environment";
import { provideHotToastConfig } from "@ngxpert/hot-toast";
import { HomeModule } from "./home/home.module";


registerLocaleData(localeDe);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// noinspection AngularInvalidImportedOrDeclaredSymbol
@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    // custom modules
    HomeModule,
    AuthModule,
    ErrorModule,
    MaterialModule,
    MainMenuModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    CommerceModule,
    NgxGoogleAnalyticsModule], providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    provideGoogleAnalytics(environment.googleAnalyticsId),
    provideGoogleAnalyticsRouter(),
    provideHotToastConfig(),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
