import { Component } from '@angular/core';
import { OperationalGraphService } from "../model/operational-graph.service";
import { MatDialog } from "@angular/material/dialog";
import { OperationGraphPropertySelectorComponent } from "../operation-graph-property-selector/operation-graph-property-selector.component";
import { FormControl } from "@angular/forms";
import { interval, Subscription } from "rxjs";
import { OperationalGraphAddEquipmentDialogComponent } from "../operational-graph-add-equipment-dialog/operational-graph-add-equipment-dialog.component";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: 'app-operational-graph-header',
  templateUrl: './operational-graph-header.component.html',
  styleUrl: './operational-graph-header.component.scss'
})
export class OperationalGraphHeaderComponent {

  refreshControl = new FormControl<number>(0);
  refreshSelection: number[] = [0, 5, 10]
  systemTime: boolean = false
  private refreshSubscription: Subscription | undefined

  constructor(
    public service: OperationalGraphService,
    private dialog: MatDialog
  ) {
  }

  ngOnDestroy() {
    this.refreshSubscription?.unsubscribe()
    this.refreshSubscription = undefined
  }

  onOpenPropertySelection() {
    let dialogRef = this.dialog.open(OperationGraphPropertySelectorComponent, {
      disableClose: true,
      maxHeight: '85vh'
    })

    dialogRef.afterClosed().subscribe(result => {
      if (!result.length) return
      this.service.onPropertySelect(result)
    })
  }

  openAddEquipmentDialog() {
    const dialogRef = this.dialog.open(OperationalGraphAddEquipmentDialogComponent, {
      width: '40vw',
      maxHeight: '90vh',
      disableClose: true,
      data: this.service.equipments[0]
    })

    dialogRef.afterClosed().subscribe(result => {
      if (!result.length) return
      this.service.equipments = [...result]
      this.service.loadTemplates()
      this.refresh()
    })
  }

  refresh() {
    this.service.refresh()
  }

  changeTimer(event: MatSelectChange) {
    let value = event.value ?? 0
    let period = value * 60 * 1000

    this.refreshSubscription?.unsubscribe()
    this.refreshSubscription = undefined
    if (value > 0) {
      this.refreshSubscription = interval(period).subscribe(_ => this.refresh())
    }
  }

  toggleSystemTime() {
    this.systemTime = !this.systemTime
    this.service.switchSystemTime(this.systemTime)
  }
}
