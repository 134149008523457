import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SparePartsBoardComponent } from "./spare-parts-board/spare-parts-board.component";
import { SparePartProductDetailsComponent } from "./spare-part-product-details/spare-part-product-details.component";
import { AuthGuard } from "../../auth/auth.guard";
import { BETA_ACCESS } from "../../auth/roles";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: { roles: [BETA_ACCESS] },
    component: SparePartsBoardComponent
  },
  {
    path: 'details',
    canActivate: [AuthGuard],
    data: { roles: [BETA_ACCESS] },
    component: SparePartProductDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SparePartsRoutingModule {
}
