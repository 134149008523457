import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, retry, shareReplay } from 'rxjs/operators';
import { LoggingService } from './logging/logging.service';
import { NgZone } from "@angular/core";
import { Page } from "./page";


export abstract class BaseService {

  api = '';
  protected retryCount = 3;


  protected constructor(protected http: HttpClient, private urlPrefix: string, private logger: LoggingService, private _zone: NgZone) {
  }

  protected getAll<T>(suffix: string = ''): Observable<Array<T>> {
    const url = this.createUrl(suffix);
    const observable = this.http.get<T[]>(url);
    return this.createPipe(observable, url, 'getAll');
  }

  protected get<T>(suffix: string, params = new HttpParams()): Observable<T> {
    const url = this.createUrl(suffix);
    const observable = this.http.get<T>(url, { params });
    return this.createPipe(observable, url, 'get');
  }

  protected download(suffix: string): Observable<any> {
    const url = this.createUrl(suffix);
    const observable = this.http.get(url, {
      responseType: 'arraybuffer',
      observe: 'response'
    });
    return this.createPipe(observable, url, 'get')
  }

  protected getResponse<T>(suffix: string, etag: string = '', params = new HttpParams()): Observable<HttpResponse<T>> {
    const url = this.createUrl(suffix);
    const observable = this.http.get<T>(url, {
      headers: this.getHeaders(etag),
      observe: 'response',
      params
    });
    return this.createPipe(observable, url, 'get');
  }

  protected put<T>(suffix: string, body: any): Observable<T> {
    // this.logger.info('[PUT] ' + JSON.stringify(body));
    const url = this.createUrl(suffix);
    const observable = this.http.put<T>(url, body);
    return this.createPipe(observable, url, 'put');
  }

  protected putText<T>(suffix: string, body: any): Observable<string> {
    // this.logger.info('[PUT] ' + JSON.stringify(body));
    const url = this.createUrl(suffix);

    const observable = this.http.put(url, body, { responseType: 'text' });
    return this.createPipe(observable, url, 'put');
  }

  protected putResponse<T>(suffix: string, body: any, params = new HttpParams()): Observable<HttpResponse<T>> {
    // this.logger.info('[POST] ' + JSON.stringify(body));
    const url = this.createUrl(suffix);
    const observable = this.http.post<T>(url, body, {
      observe: 'response',
      params
    });
    return this.createPipe(observable, url, 'post');
  }

  protected post<T>(suffix: string, body: any, params = new HttpParams()): Observable<T> {
    // this.logger.info('[POST] ' + JSON.stringify(body));
    const url = this.createUrl(suffix);
    const observable = this.http.post<T>(url, body, { params: params });
    return this.createPipe(observable, url, 'post', 1);
  }

  protected postText<T>(suffix: string, body: any): Observable<string> {
    // this.logger.info('[PUT] ' + JSON.stringify(body));
    const url = this.createUrl(suffix);

    const observable = this.http.post(url, body, { responseType: 'text' });
    return this.createPipe(observable, url, 'post');
  }

  protected postResponse<T>(suffix: string, body: any, params = new HttpParams()): Observable<HttpResponse<T>> {
    // this.logger.info('[POST] ' + JSON.stringify(body));
    const url = this.createUrl(suffix);
    const observable = this.http.post<T>(url, body, {
      observe: 'response',
      params
    });
    return this.createPipe(observable, url, 'post', 1);
  }


  protected patch<T>(suffix: string, body: any): Observable<T> {
    // this.logger.info('[PATCH] ' + JSON.stringify(body));
    const url = this.createUrl(suffix);
    const observable = this.http.patch<T>(url, body);
    return this.createPipe(observable, url, 'put');
  }

  protected patchResponse<T>(suffix: string, body: any, etag: string = '', params = new HttpParams()): Observable<HttpResponse<T>> {
    // this.logger.info('[PATCH] ' + JSON.stringify(body));
    const url = this.createUrl(suffix);
    const observable = this.http.patch<T>(url, body, {
      headers: this.getHeaders(etag),
      observe: 'response',
      params
    });
    return this.createPipe(observable, url, 'patch');
  }

  protected delete<T>(suffix: string): Observable<T> {
    const url = this.createUrl(suffix);
    const observable = this.http.delete<T>(url,);
    return this.createPipe(observable, url, 'delete');
  }

  private createPipe<T>(observable: Observable<T>, url: string, operation: string, count: number = this.retryCount): Observable<T> {
    const message = operation + ': ' + url;
    const log = this.logger;

    return observable.pipe(
      retry(count),
      // @ts-ignore
      catchError(this.handleError(message, undefined)),
      // tap<T>({
      //     complete: () => log.trace(message),
      //     error: (err: any) => log.error(message, err),
      //     next: (next: T) => log.debug(message, next)
      //   }
      // ),
      shareReplay(1)
    );
  }


  protected createUrl(suffix: string): string {
    return (suffix.length === 0) ? `${ this.api }${ this.urlPrefix }` : `${ this.api }${ this.urlPrefix }/${ suffix }`;
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  private getHeaders(etag: string = ''): any {
    if (etag !== '') {
      return {
        Accept: 'application/json',
        'If-Match': etag
      };
    }
    return {
      Accept: 'application/json'
    };
  }

  protected getServerSentEvent(suffix: string): Observable<MessageEvent> {
    const url = this.createUrl(suffix);
    return new Observable(observer => {
      const eventSource = new EventSource(url)
      eventSource.onmessage = event => {
        this._zone.run(() => {
          observer.next(event)
        })
      }
      eventSource.onerror = error => {
        this._zone.run(() => {
          observer.error(error)
        })
      }
    });
  }

  protected getPaged<T>(suffix: string, page: number, size: number, params = new HttpParams(), queryParams: string = ''): Observable<Page<T>> {
    const url = this.createUrl(suffix);
    const uri = url + "?page=" + page.toString() + "&size=" + size.toString() + queryParams
    const observable = this.http.get<Page<T>>(uri, {
      params
    });
    return this.createPipe(observable, url, 'get');
  }

  protected putPaged<T>(suffix: string, body: any, page: number, size: number, params = new HttpParams(), queryParams: string = ''): Observable<Page<T>> {
    const url = this.createUrl(suffix);
    const uri = url + "?page=" + page.toString() + "&size=" + size.toString() + queryParams
    return this.http.put<Page<T>>(uri, body, {
      params
    });
  }

  protected postPaged<T>(suffix: string, body: any, page: number, size: number, params = new HttpParams(), queryParams: string = ''): Observable<Page<T>> {
    const url = this.createUrl(suffix);
    const uri = url + "?page=" + page.toString() + "&size=" + size.toString() + queryParams
    const observable = this.http.post<Page<T>>(uri, body, {
      params
    });
    return this.createPipe(observable, url, 'post');
  }
}


export class PatchRequest<T> {
  constructor(
    public value: T
  ) {
  }
}
