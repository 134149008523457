@if(service.result.suggestedEntries.length > 0) {
  <div class="p-2 border-bottom-accent shadow-md mb-2">
    <div>{{'productConfig.result.suggested' | translate}}</div>
  </div>

  <app-product-config-search-result-panel
    [prices]="prices"
    [entries]="service.result.suggestedEntries"
    [items]="service.result.items"
    [wishlistActive]="wishlistActive"
  ></app-product-config-search-result-panel>
}

<div class="p-2 border-bottom-accent shadow-md mb-2">
  <div>{{'productConfig.result.title' | translate}}</div>
</div>

@if(service.result.entries.length > 0 && service.result.entries.length < 10) {
  <app-product-config-search-result-panel
    [prices]="prices"
    [entries]="service.result.entries"
    [items]="service.result.items"
    [wishlistActive]="wishlistActive"
  ></app-product-config-search-result-panel>
} @else if (service.result.entries.length >= 10) {
  <mat-card>
    <div class="p-3">{{'productConfig.result.tooManyResults' | translate : {resultSize : service.result.entries.length} }}</div>
  </mat-card>
} @else {
  <mat-card>
    <div class="p-3">{{'productConfig.result.invalid' | translate}}</div>
  </mat-card>
}

