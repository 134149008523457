import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SeriesRoutingModule } from './series-routing.module';
import { MaterialModule } from "../../material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { SeriesBoardComponent } from './series-board/series-board.component';
import { ComponentModule } from "../../component/component.module";
import { SeriesBoardContentComponent } from './series-board-content/series-board-content.component';
import { SeriesBoardContentResultListComponent } from './series-board-content-result-list/series-board-content-result-list.component';
import { HeaderToolbarModule } from "../../component/header-toolbar/header-toolbar.module";
import { SearchModule } from "../../component/search/search.module";
import { CategoryModule } from "../category/category.module";
import { SeriesDetailsComponent } from './series-details/series-details.component';
import { SeriesDetailsInfoComponent } from './series-details-info/series-details-info.component';
import { SeriesDetailsDocumentsComponent } from './series-details-documents/series-details-documents.component';
import { SeriesDetailsProductsComponent } from './series-details-products/series-details-products.component';
import { SeriesDetailsTabsComponent } from './series-details-tabs/series-details-tabs.component';
import { ProductBoardContentComponent } from "../product/product-board-content/product-board-content.component";
import { DocumentModule } from "../document/document.module";


@NgModule({
  declarations: [
    SeriesBoardComponent,
    SeriesBoardContentComponent,
    SeriesBoardContentResultListComponent,
    SeriesDetailsComponent,
    SeriesDetailsInfoComponent,
    SeriesDetailsDocumentsComponent,
    SeriesDetailsProductsComponent,
    SeriesDetailsTabsComponent
  ],
  exports: [
    SeriesBoardComponent
  ],
  imports: [
    CommonModule,
    SeriesRoutingModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    ComponentModule,
    HeaderToolbarModule,
    SearchModule,
    CategoryModule,
    ProductBoardContentComponent,
    DocumentModule,
  ]
})
export class SeriesModule {
}
