import { Component, Input } from '@angular/core';
import { MeanPropertySeries } from "../../../model/operational-report-activity-api";
import { ECharts, EChartsOption, SeriesOption } from "echarts";
import { MeantimeChart } from "./meantime-chart";
import { OperationalReportService } from "../../../model/operational-report.service";
import { YAXisOption } from "echarts/types/dist/shared";
import { PropertyDefinition } from "../../../../../thing-template/model/thing-template-api";
import { MatDialog } from "@angular/material/dialog";
import { MeantimeDialogComponent } from "../meantime-dialog/meantime-dialog.component";
import { SystemTimeService } from "../../../../../system/system-time/system-time.service";

@Component({
  selector: 'app-meantime-graph',
  templateUrl: './meantime-graph.component.html',
  styleUrl: './meantime-graph.component.scss'
})
export class MeantimeGraphComponent {

  @Input()
  set data(data: MeanPropertySeries | undefined) {
    if (!data) return
    this.series = data
    this.process(data)
  }

  chart: ECharts | undefined
  series: MeanPropertySeries | undefined
  initialValue: EChartsOption | null
  dynamicData: EChartsOption | null
  selectedProperty: PropertyDefinition | undefined

  constructor(
    public service: OperationalReportService,
    private systemTime: SystemTimeService,
    private dialog: MatDialog
  ) {
    this.initialValue = null
    this.dynamicData = null
    this.initialValue = new MeantimeChart().build()
    this.service.changeEvent.subscribe(reload => {
      if (reload) this.chart?.showLoading()
    })
  }

  process(series: MeanPropertySeries) {
    this.selectedProperty = series.definition
    let data = this.createSeries(series)
    this.dynamicData = {
      series: [data],
      color: '#3aaa35',
      xAxis: {
        min: () => this.service.calculateMin(),
        max: () => this.service.calculateMax()
      },
      yAxis: this.getUoMLabel()
    }
    this.chart?.hideLoading()
  }

  createSeries(series: MeanPropertySeries): SeriesOption {
    let values = series.entries.map(p => [this.systemTime.formatTimestamp(p.timestamp), p.value.toFixed(2)])
    return {
      name: series.definition.name,
      type: 'line',
      data: values,
    }
  }

  getUoMTranslation() {
    return this.service.getUoMTranslation(this.series?.definition.unitOfMeasure)
  }

  getPropertyName(isAbbreviated: boolean = false) {
    if (!this.series?.definition.name) return
    return this.service.translatePropertyName(this.series.definition.name, isAbbreviated)
  }

  getUoMLabel(): YAXisOption {
    let label = `${this.getPropertyName(true)} [${this.getUoMTranslation()}]`
    return {
      type: 'value',
      name: label,
      nameLocation: 'middle',
      nameRotate: 90,
      nameGap: 35,
      axisLine: {
        show: true
      },
      splitLine: {
        show: false
      }
    }
  }

  openPropertySelectionDialog() {
    const dialogRef = this.dialog.open(MeantimeDialogComponent, {
    }).afterClosed().subscribe(data => {
      if (!data) return
      this.changeActiveProperty(data)
    })
  }

  private changeActiveProperty(prop: string) {
    if (!this.series?.definition?.name || prop === this.series?.definition.name) return
    this.service.setMeantimeProperties(this.series.definition.name, prop)
  }

  setChart(chart: ECharts) {
    this.chart = chart
  }
}
